import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
import './style.css';

// Import all your components
import App from './component2/App';
import Login from './component2/Login';
import Homee from './compo1/Home';
import Fetch from './compo1/fetch';
import DyFetch from './compo1/DynFetch';
import AboutUs from './compo1/AboutUs';
import Blog from './compo1/Blog';
import ProductDetail from './component2/ProductDetail';
import PostFetch from './compo1/PostFetch';
import Jaquar from './component2/Jaquar';
import WasBasin from './component2/WasBasin';
import TapSlider from './component2/TapSlider';
import BasinProductDetails from './component2/BasinDetails'; 
import Comparision from './component2/Comparision';
import DynBrands from './component2/DynBrands';
import ComparisonProduct from './component2/Comparision';
import FetchLoginDetail from './compo1/FetchLoginDetails';
import ComparisionReport from './component2/ComparisionReport';
import SimilarProductComparision from './component2/SimilarComparison';
import SimilarComparisonModal from './component2/SmilarComparisonMOdal';
import SimilarComparisonReport from './component2/SimilarComparisonReport';
import QuotationGridReport from './component2/QuotationGridReport';
import CustomerMasterDetails from './component2/CustomerMasterDetails';
import CustomerComparisonReport from './component2/QuotationComparisonReport';
import QuotationComparisonReport from './component2/QuotationComparisonReport';
import LeadComparisonGridReport from './component2/LeadComparisonGridReport';
import LeadComparisonMasterReport from './component2/LeadComparisonMasterReport';
import Quotation from './component2/Quotation';
import DifferentProductComparison from './component2/DifferentBrandProducts';
import DifferentBrandProductReport from './component2/DifferentBrandProductReport';
import ComparisonPage from './component2/ComparisonPage';
import FinalQuotationGridReport from './component2/FinalQuotationGridReport';
import FinalQuotationLeadReport from './component2/FinalQuotationLeadReport';
import PredefinedSetes from './component2/PredefinedSetes';
import PreDefinedMasterReport from './component2/PreDefinedMasterReport';
import FinalQuotationReport from './component2/FinalQuotationReport';

// Create routes with conditional login check
const AppRoutes = ({ isAuthenticated, onLogin }) => {
  const routes = createBrowserRouter([
    {
      path: "/",
      element: isAuthenticated ? <App /> : <Login onLogin={onLogin} />,
      children: [
        { index: true, element: <Homee /> },
        { path: '/home', element: <Homee /> },
        { path: '/fetch', element: <Fetch /> },
        { path: '/Dyfetch', element: <DyFetch /> },
        { path: '/about', element: <AboutUs /> },
        { path: '/dynB', element: <DynBrands /> },
        { path: '/compare', element: <ComparisonPage/> },
        { path: '/comparPro', element: <ComparisonProduct /> },
        { path: '/comparReport', element: <ComparisionReport/> },
        { path: '/blog', element: <Blog /> },
        { path: '/Pdet', element: <ProductDetail /> },
        { path: '/differntProd', element: <DifferentProductComparison /> },
        { path: '/differntProdReport', element: <DifferentBrandProductReport/> },
        { path: '/Pfetch', element: <PostFetch /> },
        { path: '/jaq', element: <Jaquar /> },
        // {path: '/login', element: <Login />},
        { path: '/washB', element: <WasBasin /> },  
        { path: '/taps', element: <TapSlider /> },
        { path: '/CustomerDetails', element: <CustomerMasterDetails /> },
        { path: '/Basindet', element: <BasinProductDetails /> },
        { path: '/gridQuotation', element: <QuotationGridReport/> },  
        { path: '/custCompareReport', element: <QuotationComparisonReport/> },    
        { path: '/leadCompareGridReport', element: <LeadComparisonGridReport/> },       
        { path: '/leadCompareMasterReport', element: <LeadComparisonMasterReport/> },
        { path: '/compa', element: <Comparision /> },
        { path: '/similar', element: <SimilarProductComparision/> },
        { path: '/SimilarcomparReport', element: <SimilarComparisonReport/> },
        { path: '/fetchLogin', element: <FetchLoginDetail /> },
        { path: '/preDefined', element: <PredefinedSetes /> },
        { path: '/preDefinedMaster', element: <PreDefinedMasterReport /> },
        { path: '/quotation', element: <Quotation /> },
        { path: '/finalquotationReport', element: <FinalQuotationReport /> },


        { path: '/finalQuotationGrid', element: <FinalQuotationGridReport/> },
        { path: '/finalQuotationlead', element: <FinalQuotationLeadReport/> }
      ]
    }
  ]);

  return <RouterProvider router={routes} />;
};

function AppContainer() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check sessionStorage for authentication status when app loads
  useEffect(() => {
    const storedAuthStatus = sessionStorage.getItem('isAuthenticated');
    if (storedAuthStatus === 'true') {
      setIsAuthenticated(true); // User is authenticated, so set state to true
    }
  }, []);

  const handleLogin = (status) => {
    setIsAuthenticated(status); // Set authentication state after successful login
    if (status) {
      sessionStorage.setItem('isAuthenticated', 'true'); // Save authentication status in sessionStorage
    } else {
      sessionStorage.removeItem('isAuthenticated'); // Remove authentication status if logged out
    }
  };

  return <AppRoutes isAuthenticated={isAuthenticated} onLogin={handleLogin} />;
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<AppContainer />);

reportWebVitals();
