import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './CustomerMasterDetails.css';

export default function CustomerMasterDetails() {
  const [product, setProduct] = useState(null);  // State to store product details
  const [loading, setLoading] = useState(true);  // Loading state
  const [error, setError] = useState(null);      // Error state
  const [quotations, setQuotations] = useState([]); // State to store quotations
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const itemsPerPage = 10; // Items per page
  const[Message,setMessage]=useState('')
  const navigate = useNavigate(); // useNavigate to navigate between pages

  // Retrieve the product ID from sessionStorage
  const customerId = sessionStorage.getItem('selectedCustomerQuoteId');
  console.log('customerId', customerId);

  useEffect(() => {
    
    // Fetch product details using the product ID
    axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getCustomerMasterReportDetails01', {
      one: customerId,
      authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs",
    })
      .then(res => {
        if (res.data && res.data.jsArray && res.data.jsArray.length > 0) {
          setProduct(res.data.jsArray[0]);  // Assuming the response contains the product data
          console.log(res.data.jsArray[0]);
        } else {
          setError('No product data found');
        }
        setLoading(false);
      })
      .catch(err => {
        setError('Error fetching product details');
        setLoading(false);
      });
  }, []); // Fetch product details on component mount

  useEffect(() => {
  

    // Fetch quotations using the product ID
    axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getCustFinalQuote', {
      one: customerId,
      authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs",
    })
      .then(res => {
        if (res.data && res.data.length > 0) {
          setQuotations(res.data);  // Assuming the response contains the quotations
          console.log(res.data);
        }
        else if (res.data && res.data.length === 0) {
          setMessage("No Single Comparison Assign to this customer");  // Assuming the response contains the quotations
          
        } else {
          setError('No quotations found');
        }
        setLoading(false);
      })
      .catch(err => {
        setError('Error fetching quotations');
        setLoading(false);
      });
  }, []); // Fetch quotations on component mount

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginate = (items, page, itemsPerPage) => {
    const startIndex = (page - 1) * itemsPerPage;
    return items.slice(startIndex, startIndex + itemsPerPage);
  };

  const totalPages = Math.ceil(quotations.length / itemsPerPage);

  // Pagination items to display for the current page
  const currentQuotations = paginate(quotations, currentPage, itemsPerPage);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Handle Product Click for navigating to the product page
  const handleNameClick = (quotation) => {
    sessionStorage.setItem('selectedComparisionId', quotation.bca_comparison_id); // Store product ID
    console.log('selectedComparisionId',quotation.bca_comparison_id);
    
    navigate('/custCompareReport'); // Navigate to the ProductMaster page

  };

  return (
    <div className="container" style={{
      background: "white",
      borderRadius: "15px",
      padding: "5px",
      width: "100%",
      maxHeight: "auto",  // Ensure the height adapts to content
      position: "relative",
      zIndex: "1",
      scrollbarWidth: "none",
      marginBottom: "0px"  // Add space at the bottom to avoid footer overlap
    }}>
      <div style={{ marginTop: "100px" }}>
        {product ? (
          <div>
            {/* Product Information */}
            <div className="row" style={{ borderRadius: "15px" }}>
              <h2 style={{ color: "#4CAF50", textAlign: "center" }}>{product.customer_name}</h2>
              <div className="row">
                <div className="col col-lg-6 col-md-6 col-12">
                  <div className="d-flex justify-content-left">
                    <strong>Employee Name:</strong> <span>&nbsp;{product.customer_name}</span>
                  </div>
                  <div className="d-flex justify-content-left">
                    <strong>Contact:</strong> <span>&nbsp;{product.contact_number}</span>
                  </div>
                  <div className="d-flex justify-content-left">
                    <strong>Email Id:</strong> <span>&nbsp;{product.email}</span>
                  </div>
                  <div className="d-flex justify-content-left">
                    <strong>Address:</strong> <span>&nbsp;{product.office_address}</span>
                  </div>
                </div>
                <div className="col col-lg-3 col-md-3 col-12"></div>
                <div className="col col-lg-3 col-md-3 col-12">
                  <div className="d-flex justify-content-left">
                    <strong>PAN No:</strong> <span>&nbsp;{product.customer_panno}</span>
                  </div>
                  <div className="d-flex justify-content-left">
                    <strong>Contact:</strong> <span>&nbsp;{product.customer_alt_contact}</span>
                  </div>
                  <div className="d-flex justify-content-left">
                    <strong>GST No:</strong> <span>&nbsp;{product.customer_gstno}</span>
                  </div>
                  <div className="d-flex justify-content-left">
                    <strong>Status:</strong> <span>&nbsp;{product.status}</span>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h4 style={{ marginTop: "10px", textAlign: "center", color: "#4CAF50" }}>
                Customer Comparison Grid Report
              </h4>
              <table className="quotation-table">
                <thead>
                  <tr>
                    <th>Quotation Name</th>
                    <th>Date</th>
                    {/* <th>Status</th> */}
                  </tr>
                </thead>
                <tbody>
                  <p style={{textAlign:"left", color:"#4CAF50",fontSize:"18px"}}> {Message}</p>
                  {currentQuotations.map((quotation, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'even-row' : ''}>
                      <td
                        style={{ cursor: 'pointer', textDecoration: 'underline', color: "#64a0d5" }}
                        onClick={() => handleNameClick(quotation)}
                      >
                        QTN-{quotation.bca_comparison_id}
                      </td>
                      <td>{quotation.bca_assigned_at}</td>
                      {/* <td>{quotation.btcust_customer_name}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination Controls */}
            <div className="pagination-controls">
              <button
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                First
              </button>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
              <button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                Last
              </button>
            </div>

            <div className="row d-flex justify-content-center">
              <Link
                to={'/gridQuotation'}
                className="btn btn-outline-success"
                type="button"
                style={{ width: "300px", height: "40px", margin: "5px" }}
              >
                Customer Quotation Grid Report
              </Link>
            </div>
          </div>
        ) : (
          <p>No product details available</p>
        )}
      </div>
    </div>
  );
}
