import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ComparisonProductDetailModal = ({ productDetailsId, onClose }) => {
    const [product, setProduct] = useState(null);  // State to store product details
    const [loading, setLoading] = useState(true);  // Loading state
    const [error, setError] = useState(null);      // Error state

    useEffect(() => {
        // Fetch product details using the product ID
        axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getProductMasterReportDetails', {
            one: productDetailsId,    // Using the passed productDetailsId prop
            authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs",
        })
            .then(res => {
                setProduct(res.data.jsArray[0]);  // Assuming the response contains the product data
                setLoading(false);
            })
            .catch(err => {
                setError('Error fetching product details');
                setLoading(false);
            });
    }, [productDetailsId]);  // The hook depends on productDetailsId to fetch the details

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }
    
  return (
    <div
      className="modal-overlay"
      style={{
        display: "block",
        position: "fixed",        // Use fixed positioning to make the modal stay fixed on the screen
        top: 0,                   // Position it from the top of the screen
        left: 0,                  // Position it from the left of the screen
        width: "100%", 
        top:"8%",           // Make it take full width
        height: "90%",           // Take full height to cover the screen
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Add a transparent overlay
        zIndex: 1050              // Ensure it appears above other content
      }}
      tabIndex="-1"
      role="dialog"
    >
      <div
        className="modal-dialog"
        role="document"
        style={{
          position: "absolute",   // Positioning relative to the viewport
          top: "50%",             // Center vertically
          left: "50%",            // Center horizontally
          transform: "translate(-50%, -50%)" , // Use transform to adjust centering
          width: "90%"  
        }}
      >
        <div className="modal-content">
        <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
  <h5 style={{ flex: 1, textAlign: 'center' }}>Product Information</h5>
  <span className="modal-close-button" onClick={onClose} style={{ cursor: 'pointer', fontSize: '24px' }}>×</span>
</div>

          <div className="modal-body">
            

            {product ? (
                <div>
                    {/* First row for product image and details */}
                    <div className="row" style={{borderRadius:"15px"}}>
                        <h2 style={{color:"#FFC300",textAlign:"center"}}>{product.btp_product_name}</h2>
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            {/* Image display */}
                            <img 
                                src={product.btp_image1 ? `http://192.168.0.139:8965/MI_Applications/BTrading/BrandLogo/${product.btp_image1}` : 'https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI='} 
                                alt={product.bts_series_name} 
                                className="img-fluid" 
                                style={{height:"250px",width:"100%",borderRadius:"5px"}}
                            />
                        </div>

                                  <div className="col-lg-7 col-md-7 col-sm-12">
                                      {/* Product details */}
                                      <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between", fontSize: "14px" }}><strong>Price:</strong> {product.btp_mrp_price}</p>
                                      <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between", fontSize: "14px" }}><strong>Sale Price:</strong> {product.sale_rate}</p>
                                      <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between", fontSize: "14px" }}><strong>Brand:</strong> {product.btb_brand_name}</p>
                                      <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between", fontSize: "14px" }}><strong>Series:</strong> {product.bts_series_name}</p>
                                      <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between", fontSize: "14px" }}><strong>Category:</strong> {product.btc_category_name}</p>
                                      <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between", fontSize: "14px" }}><strong>Color:</strong> {product.btco_color_name}</p>
                                      <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between", fontSize: "14px" }}><strong>Status:</strong> {product.btp_product_status}</p>
                                      <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between", fontSize: "14px" }}><strong>Dimension:</strong> {product.btp_dimension}</p>
                                      <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between", fontSize: "14px" }}><strong>Warranty:</strong> {product.btp_warranty_period && product.btp_warranty_period.match(/\d+/) ? product.btp_warranty_period.match(/\d+/)[0] : 'N/A'} Months</p>
                                      <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between", fontSize: "14px" }}><strong>Discount:</strong> {product.btp_max_discount && product.btp_max_discount.match(/\d+/) ? product.btp_max_discount.match(/\d+/)[0] : 'N/A'}%</p>
                                      <p style={{ marginBottom: '3px', display: "flex", justifyContent: "space-between", fontSize: "14px" }}><strong>Product Code:</strong> {product.btp_product_code_no}</p>


                                  </div>
                              </div>

                              {/* Second row for additional details */}
                              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                                  <li style={{ marginBottom: '3px', fontSize: "14px" }}>{product.btp_description1}</li>
                                  <li style={{ marginBottom: '3px', fontSize: "14px" }}>{product.btp_description2}</li>
                              </ul>

                          </div>
                      ) : (
                          <p>No product details available</p>
                      )}
                  </div>
              </div>
          </div>
    </div>
  );
};

export default ComparisonProductDetailModal;
