import React, { useState, useEffect, useRef } from 'react';
import './Login.css';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

export default function Login({ onLogin }) {
  const [formData, setFormData] = useState({
    username: '',
    pass: ''
  });
  const [error, setError] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false); // Add state for forgot password

  const usernameInputRef = useRef(null);

  useEffect(() => {
    if (usernameInputRef.current) {
      usernameInputRef.current.focus();
    }
  }, []);

  const captureForm = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getLoginDetails', {
      one: formData.username.trim(),
      two: formData.pass.trim(),
      authApiKey: 'ytfkenaojjawmbjnbsyyj-vhvbs',
    })
      .then((res) => {
        console.log('res.data',res.data)
        console.log('em_id value',res.data[0].em_id)
        setError(''); // Clear any previous errors
        if (res.data[0].message === 'Login Successfull') {
          sessionStorage.setItem('isAuthenticated', 'true');
          sessionStorage.setItem('em_id',res.data[0].em_id); // Store em_id or any other data you need
          const em_id = sessionStorage.getItem('em_id'); 
          console.log('em_iddddddddddd',em_id)
          onLogin(true);
          Navigate('/home')

          
        } else {
          setError(res.data[0].message); // Set error message if login fails
        }
      })
      .catch((error) => {
        console.error('There was an error!', error);
       
        setError('An error occurred while trying to log in.');
      });
     
  };

  const toggleForgotPassword = () => {
    setIsForgotPassword(!isForgotPassword); // Toggle forgot password form
  };

  return (
    <div className="login-background">
      <div className="login_container">
        {/* Login Form */}
        {!isForgotPassword ? (
          <div className="form-container" id="login-form">
            <h1>Login</h1>
            <form onSubmit={handleSubmit}>
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={captureForm}
                ref={usernameInputRef}
                required
             
              />

              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="pass"
                value={formData.pass}
                onChange={captureForm}
                required
              />


              {error && <p className="error-message">{error}</p>} {/* Display error message */}

              <button type="submit" className="login-button" style={{ fontSize: '15px', padding: '5px' }}>
                Login
              </button>
            </form>
            <p>
       
            </p>
          </div>
        ) : (
          <div className="form-container" id="forgot-password-form">
            <h1>Forgot Password</h1>
            <p>Please contact support to reset your password.</p>
            <button onClick={toggleForgotPassword}>Back to Login</button>
          </div>
        )}
      </div>
    </div>
  );
}
