import React, { useState, useEffect } from 'react';
import './Comparison.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import InfoCard from './InfoCard';
import ComparisonReportModal from './ComparisionModal';
import ComparisonProductDetailModal from './ComparisonProductDetailModal';


const ComparisonPage = () => {

    const [comparisonList, setComparisonList] = useState(
        JSON.parse(sessionStorage.getItem('comparisonList')) || []
      );
      const [comparisonData, setComparisonData] = useState([]); // Store the API response data
      const [Clearmsg, setClearmsg] = useState(''); // Clear session storage msg
 
 // Function to clear the comparison list from session storage and reset state
 const handleClearComparison = () => {
    sessionStorage.removeItem('comparisonList');
    setComparisonData([]);  // Reset comparisonData to clear the table
    setComparisonList([]);
    setClearmsg("Remove product from comparison successfully.");

    setTimeout(() => {
      setClearmsg('');
    }, 2000); // 2000ms = 2 seconds
  };

  return (
    < >
      <div className='main'>
     <div className="comparison-container" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                    <div className="row justify-content-evenly" style={{ marginTop: "70px" }}>
                        <h2 style={{textAlign:"center",fontSize:"24px"}}></h2>
                        {/* Display all products as individual cards */}
                        
                    </div>
                </div>

              <div className='container'>
                  <div className='row'>
                    <h2 style={{textAlign:"center"}}>Comparison Section</h2>
                      <div className='col col-12 col-lg-3'>
                          <div class="card text-center" style={{width:"15rem",height:"150px"}}>
                              <div class="card-body">
                                  <h5 style={{fontSize:"18px",color:"rgb(13, 119, 142)"}} class="card-title" title='BrandWise Comparison'>BrandWise Comparison</h5>
                                  <p class="card-text">Show comparison Between products of Different brands</p>
                                  <Link to={'/comparPro'} class="btn btn-outline-success">Show Comparison</Link>
                              </div>
                          </div>
                      </div>

                      <div className='col col-12 col-lg-3'>
                          <div class="card text-center" style={{width:"15rem",height:"150px"}}>
                              <div class="card-body">
                                  <h5 style={{fontSize:"18px",color:"rgb(13, 119, 142)"}} title='Similar Brand comparison' class="card-title">Similar Brand comparison</h5>
                                  <p class="card-text">Show comparison Between products of Similar brands.</p>
                                  <Link to={'/similar'} class="btn btn-outline-success">Show Comparison</Link>
                              </div>
                          </div>
                      </div>


                      <div className='col col-12 col-lg-3'>
                          <div class="card text-center" style={{width:"15rem",height:"150px"}}>
                              <div class="card-body">
                                  <h5 style={{fontSize:"18px",color:"rgb(13, 119, 142)"}} title='Combine Product Comparison' class="card-title">Combine Product Comparison</h5>
                                  <p class="card-text">Combinations of products of different brands.</p>
                                  <Link to={'/differntProd'} class="btn btn-outline-success">Show Comparison</Link>
                              </div>
                          </div>
                      </div>


                      <div className='col col-12 col-lg-3'>
                          <div class="card text-center" style={{width:"15rem",height:"150px"}}>
                              <div class="card-body">
                                  <h5 style={{fontSize:"18px",color:"rgb(13, 119, 142)"}} title='Comparison Grid Report' class="card-title">Comparison Grid Report</h5>
                                  <p class="card-text">Show all comparison Grid Report details.</p>
                                  <Link to={'/gridQuotation'} class="btn btn-outline-success">Show Report</Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

    <p style={{textAlign:"center",marginBottom:"0px",color:"green",marginTop:"5px"}}>{Clearmsg}</p>

{/* ------------------------------------------------------------ */}
        <section id="alt-services" class="alt-services section" style={{marginTop:"-20px"}}>

<div class="container aos-init aos-animate py-5" data-aos="fade-up" data-aos-delay="100">

  <div class="row gy-4">

    <div class="col-lg-6 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
      <div class="service-item position-relative">
        <div class="img">
          <img src="img/interior.jpg" class="img-fluid" alt="" />
        </div>
        <div class="details">
          <span  class="stretched-link">
            <h3>Innovative Interior Concepts</h3>
          </span>
          <p>Transform your space with our expert interior design services. We create aesthetically pleasing and functional spaces that reflect your unique style and personality. From conceptualization to execution, our team ensures that every detail is meticulously designed to suit your needs.</p>
        </div>
      </div>
    </div>

    <div class="col-lg-6 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
      <div class="service-item position-relative">
        <div class="img">
          <img src="img/interior-3.jpg" class="img-fluid" alt="" />
        </div>
        <div class="details">
          <span  class="stretched-link">
            <h3>Modern Interior Design</h3>
          </span>
          <p>Embrace the sleek, sophisticated look of modern interior design. Our designs focus on clean lines, minimalist furniture, and innovative use of space to create a contemporary and timeless atmosphere for your home or office.</p>
        </div>
      </div>
    </div>
</div>
</div>
       </section> 

       <button class="clr-Btn" onClick={handleClearComparison}>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x sign" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
</svg>
<div class="text" >Clear Comparison</div>
</button>


</div>



    </>
  );
};

export default ComparisonPage;
