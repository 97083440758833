import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

export default function PreDefinedMasterReport() {
    const [lead, setLead] = useState(null);  // State to store lead details
    const [loading, setLoading] = useState(true);  // Loading state
    const [error, setError] = useState(null);      // Error state

    const navigate = useNavigate();
    const em_id = sessionStorage.getItem('em_id'); 
    // Manage session
    useEffect(() => {
        const em_id = sessionStorage.getItem('em_id'); 
        if (!em_id) {
            navigate('/');
        }
    }, [navigate]);         

    const SetName = sessionStorage.getItem('selectedSetName');
        console.log(SetName); 

        const FinalTotal = sessionStorage.getItem('selectedFinalTotal');
        console.log(FinalTotal);

    useEffect(() => {
        // Retrieve the lead ID from sessionStorage
        const SetId = sessionStorage.getItem('selectedSetId');
        console.log(SetId);

        if (SetId) {
            // Fetch lead details using the lead ID
            axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getSinglePredefinedSets', {
                one: SetId,
                authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs",
            })
                .then(res => {
                    setLead(res.data);  // Assuming the response contains the lead data
                    console.log(res.data[0]);
                    setLoading(false);
                })
                .catch(err => {
                    setError('Error fetching lead details');
                    setLoading(false);
                });
        } else {
            setError('No lead ID found in sessionStorage');
            setLoading(false);
        }
    }, []);  // Empty dependency array, so this runs once on component mount

    if (loading) {
        return (
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <h3>Loading...</h3>
            </div>
        );
    }

    if (error) {
        return (
            <div style={{ textAlign: 'center', padding: '20px', color: 'red' }}>
                <h3>{error}</h3>
            </div>
        );
    }

    return (
      <div className="container" style={{ marginTop: "0px",marginBottom:"50px" }}>
         <div className='container' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p style={{ color: "#000000", textAlign: "center", flex: 1,fontSize:"23px",marginTop:"75px"  }}>PreDefined set-{SetName}</p>
               
                <Link to={'/hotelD/allPreSets'} className="btn btn-info btn-sm" type="button" style={{marginRight:"auto",marginRight:"5px" }}>
                    Predefined grid report
                </Link>
                <Link to={'/hotelD/modifyPreSets'} className="btn btn-info btn-sm" type="button" style={{  marginLeft: 'auto' }}>
                    Modify Set
                </Link>
     </div>
        <div className="row">
          {lead && lead.map((set, index) => (
            <div key={index} className="col-md-4 col-lg-3 col-sm-6 mb-4">
              <div class="card" style={{border:"0px",boxShadow:"5px 5px 15px gray",padding:"5px",width:"15rem"}} >

                <img src={set.btp_image1 ? `http://192.168.0.139:8965/MI_Applications/BTrading/BrandLogo/${set.btp_image1}` : 'https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI='}
 class="card-img-top" alt="..." style={{height:"150px",width:"100%",borderRadius:"0px",marginTop:"0px"}}/>
                  <div class="card-body">
                  <p className="card-text" style={{ fontSize: '15px', color: "#0d778e", fontWeight: "700", cursor: "pointer", marginBottom: "8px", whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis' }} title={set.btp_product_name}>{set.btp_product_name}</p>
                  <p style={{ color: "black", fontSize: "13px", marginBottom: "3px" }}><small>{set.series_name}</small></p>
                  <p style={{ color: "black", fontSize: "13px", marginBottom: "3px" }}><span>Sale Price: &nbsp;{set.btpsp_price}</span></p>
                  <p style={{ color: "black", fontSize: "13px", marginBottom: "3px" }}><span>Brand: &nbsp;{set.btpsp_brand_name}</span></p>
                  <p style={{ color: "black", fontSize: "13px", marginBottom: "3px" }}><span>Code: &nbsp;{set.btp_product_code_no}</span></p>
                  <p style={{ color: "black", fontSize: "13px", marginBottom: "3px" }}><b style={{ color: "gray" }}>Series:&nbsp;{set.bts_series_name || 'SA1254'}</b></p>
                  </div>
              </div>

            </div>
          ))}
          <h2 style={{textAlign:"center",fontSize:"23px",fontWeight:"700",color:"#0d778e"}}>Total Amount Of Products of {SetName}-<i class="bi bi-currency-rupee"></i>{FinalTotal} </h2>
        </div>
      </div>
    );
}
