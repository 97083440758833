import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import './DynBrands.css';
import Products from './Products';
import InfoCard2 from './InfoCards2';
import { useLocation } from 'react-router-dom';

export default function DynBrands() {
  const [Info, setInfo] = useState([]);
  const [brandId, setBrandId] = useState(null); // State to hold the selected brandId
  const [brandName, setbrandName] = useState('')
  // const [ItemId, setItemId] = useState(null); // State to hold the selected brandId
  // const [ItemName, setItemName] = useState('')
  const scrollContainerRef = useRef(null); // Reference for the scrollable container

  const itemId = sessionStorage.getItem('itemId');
    const itemName = sessionStorage.getItem('itemName');

  console.log('bbbbbbbbbbbb',itemId,itemName);
  
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const res = await axios.post('http://localhost:5000/proxy/brandCategorypoint', {
        //  const res = await axios.post('http://192.168.0.139:9000/proxy/brandpoint', {
        const res = await axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getSeriesMasterDetails01', {
           one: itemId,
          authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
        });
        setInfo(res.data); // Store response data in state
        console.log(res.data); // To verify the structure of response
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  // Function to scroll the container to the right
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: window.innerWidth <= 768 ? 150 : 200, // Adjust scroll distance based on screen size
        behavior: 'smooth', // Smooth scrolling effect
      });
    }
  };

  // Function to scroll the container to the left
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: window.innerWidth <= 768 ? -150 : -200, // Adjust scroll distance based on screen size
        behavior: 'smooth', // Smooth scrolling effect
      });
    }
  };

  // Handle item click and set the selected brandId
  const handleItemClick = (id, name) => {
    setBrandId(id);  // Update the brandId state when an item is clicked
    setbrandName(name)
    console.log("Selected Brand ID: ", id); // You can use this brandId for any further logic
    console.log("Selected Brand Name: ", name); // You can use this brandId for any further logic
  };


  return (
    <>
      <div className='container'>
        <div className='container' style={{paddingTop:"80px"}}>
          <h3 style={{ color: "rgb(255, 0, 79)", fontWeight: "900px", textAlign: "center" }}>Series of the brand {itemName} are here.</h3>
          <div className="container" style={{margin: "15px" }} >
            <div className='row d-flex justify-content-evenly'>
             
              {Info.length > 0 ? (
                Info.map((item, index) => (
                  <div
                    className="col-12 col-lg-6 col-md-6 col-sm-6"
                    key={index}
                  
                  >
                  
                      {/* Brand name section */}
                      <div className="p-0"         onClick={() => handleItemClick(item.bts_series_id, item.bts_series_name)} style={{ textAlign: 'center', height:"40px", border : "1px solid black", margin:"5px", display:"grid", justifyContent:"center", alignItems:"center"}}>
                        <p
                          className="nav-linked"
                          style={{
                            fontSize: '15px',
                            color: "#0d778e",
                            fontWeight: "700",
                            cursor: "pointer",
                            marginBottom:"0px",
                            whiteSpace: "nowrap", // Prevent text from wrapping
                            overflow: "hidden",  // Hide the overflow text
                            textOverflow: "ellipsis", // Add ellipsis (...) at the end
                            maxWidth: "180px",   // Set a max width based on your design needs
                            display: "inline-block", // Keep the element inline for truncation
                          }}
                          title={item.bts_series_name} // Show full name as tooltip
                   // Update brandId on click
                        >
                          <b>{item.bts_series_name}</b>
                        </p>
                      </div>
                    </div>
                ))
              ) : (
                <p>Loading data...</p>
              )}
              </div>
            
            </div>


            <Products brandId={brandId} brandName={brandName} />

            <div className='container mb-2'>
     <div className='container '>
     <div className='container'>
    <div className='row mt-4 d-flex justify-content-center'>
        <div className='col col-12 col-md-8 col-lg-5 '>
            <img 
                src='https://images.unsplash.com/photo-1507652313519-d4e9174996dd?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YmF0aHJvb218ZW58MHx8MHx8fDA%3D' 
                style={{borderRadius:"10px", width:"100%", height:"auto"}}
                alt="Bathroom"
            />
        </div>
        <div className='col-12 col-md-0 col-lg-1'>
            {/* You can put content here if needed */}
        </div>
        <div className='col-12 col-md-8 col-lg-5'>
            <h3 style={{color:"#ff004f"}}>
            WeAreOne Trading Company.
            </h3>
            <p style={{color:"gray", fontFamily:"Poppins"}}>
            Kindly provide the required data for display in this section.Kindly provide the required data for display in this section.Kindly provide the required data for display in this section.
            </p>
        </div>
    </div>
</div>

    
      </div>
    </div>
        
         
          <InfoCard2 />
        </div>
      </div>
    </>

  );
}
