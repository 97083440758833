import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import {Link, useNavigate } from 'react-router-dom';


const DifferentProductModal = ({ isOpen, onClose,QuoteId }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categoryId, setCategoryId] = useState(null);
  const [colors, setColors] = useState([]);
  const [selectedColor, setSelectedColor] = useState('');
  const [colorId, setColorId] = useState(null); // Color ID
  const [quantity, setQuantity] = useState(''); // State for quantity input
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingColors, setLoadingColors] = useState(true);
  const menuInputRef = useRef(null);
  const [infoo, setInfoo] = useState([]); // Initialize as an empty array
  const [isComparisonSuccessful, setIsComparisonSuccessful] = useState(false); //show report button
  const [errorMessage, setErrorMessage] = useState(''); // Add error message state



console.log('QuoteIdqqqqq',QuoteId);
const navigate = useNavigate();

const em_id = sessionStorage.getItem('em_id'); 
console.log('em_iddddddddddd',em_id)

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getLeadMasterReportDetails', {
          authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
        });

        const categoriesWithId = res.data.jsArray.map(category => ({
          id: category.leadID,
          name: category.leadName,
        }));
        setCategories(categoriesWithId);
        setLoadingCategories(false); // Stop loading once categories are fetched
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoadingCategories(false);
      }
    };

    fetchCategories();
    
    // Focus the input field when the modal opens
    if (menuInputRef.current) {
      menuInputRef.current.focus();
    }
  }, []); // This useEffect runs only once when the component is mounted

  // Function to fetch colors from the API
  // const getColors = async () => {
  //   try {
  //     const colorsRes = await axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getCustomerMasterReportDetails01', {
  //       authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
  //     });
  //     const colorsWithId = colorsRes.data.jsArray.map(color => ({
  //       id: color.customer_id,
  //       name: color.customer_name
  //     }));
  //     setColors(colorsWithId);
  //     setLoadingColors(false); // Stop loading once colors are fetched
  //   } catch (error) {
  //     console.error('Error fetching colors:', error);
  //     setLoadingColors(false);
  //   }
  // };

  // useEffect(() => {
  //   getColors();
  // }, []); // Empty array ensures it runs only once when the component mounts

  if (!isOpen) return null; // Don't render the modal if it's not open

  // Function to handle submission
  const AddComparision = () => {
    
    if (!categoryId) {
      setErrorMessage('Please select a lead.');
      return;
    }
    if (!quantity) {
      setErrorMessage('Please enter a quantity.');
      return;
    }
    
  
  
 // If validation passes, clear the error message
 setErrorMessage('');

    axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/setAssignComapreMasterDetails', {
      "one": QuoteId,
      "two": categoryId,             //leadId
      "three": "null",              //customerId
      "four": em_id,
      "five": quantity,
      "authApiKey": "ytfkenaojjawmbjnbsyyj-vhvbs"
    })
    .then(res => {
      setInfoo(res.data);
      console.log(res.data);
      // Check if the response data is "Success"
    if (res.data === "Success") {
      setIsComparisonSuccessful(true);
    } else {
      setIsComparisonSuccessful(false);
    }
     
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
  };

  const handleShowReportClick = () => {
    sessionStorage.setItem('ComparisionId', QuoteId); // Save QuoteId to sessionStorage
  };

  return (
    <div className="modal fade show" style={{ display: 'block' ,backgroundColor: 'rgba(0, 0, 0, 0.5)'}} tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '90%', width: '90%' }}>
        <div className="modal-content">
          <div className="modal-header" style={{ textAlign: 'center' }}>
            <h5  id="staticBackdropLabel" style={{color:"#0e7985 ",textAlign:"center"}}>Comparison Report Between Products of Different Brands</h5>
            <button type="button" className="btn-close" onClick={onClose} style={{color:"#0e7985 ",textAlign:"center"}} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {/* Modal Content */}
            {/* <p>Your comparison report content goes here.</p> */}
            <div className='container'>
              <div className='row d-flex justify-content-between'>
                {/* First column: Lead Name Dropdown */}
                <div className='col-12 col-lg-6 mb-0'>
                  <label style={{ color: "#000000" }}>Select Lead Name</label>
                  <div className="btn-group">
                    <button type="button" className="btn dropdown-toggle" style={{ width: "223px", background: "#e1dcde" }} data-bs-toggle="dropdown" aria-expanded="false">
                      {loadingCategories ? 'Loading...' : selectedCategory || 'Select Lead'}
                    </button>
                    <ul className="dropdown-menu" style={{ maxHeight: "200px", overflowY: "auto" }}>
                      {categories.map((category) => (
                        <li key={category.id}>
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={() => {
                              setSelectedCategory(category.name);
                              setCategoryId(category.id);
                            }}
                          >
                            {category.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* First column end here */}

                {/* Second column: Customer Dropdown */}
                {/* <div className='col-12 col-lg-6 mb-0'>
                  <label style={{ color: "#000000" }}>Select Customer</label>
                  <div className="btn-group">
                    <button type="button" className="btn dropdown-toggle" style={{ width: "223px", background: "#e1dcde" }} data-bs-toggle="dropdown" aria-expanded="false">
                      {loadingColors ? 'Loading...' : selectedColor || 'Select Customer'}
                    </button>
                    <ul className="dropdown-menu" style={{ maxHeight: "200px", overflowY: "auto" }}>
                      {colors.map((color) => (
                        <li key={color.id}>
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={() => {
                              setSelectedColor(color.name);
                              setColorId(color.id);
                            }}
                          >
                            {color.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div> */}
                {/* Second column end here */}
               
                {/* <p style={{color:"gray",textAlign:"center",marginTop:"3px"}}>Please select either a customer or a lead, not both.</p> */}
                <div className="col-12 col-lg-6 mb-0">
                  <label style={{ color: "#000000" }}>Quantity</label>
                  <input
                    type="number"
                    className="form-control"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    placeholder="Enter quantity"
                    style={{width: "223px"}}
                  />
                </div>
              
              </div>
              {/* New Row for Quantity Input Field */}
              <div className="row d-flex">
                
              </div>
             
            </div>
          </div>
          <div className="modal-footer">
    
    <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
    <button type="button" className="btn btn-outline-secondary" onClick={AddComparision}>Submit</button>
    {isComparisonSuccessful && (
      <Link to={'/differntProdReport'}>
        <button type="button" className="btn btn-outline-secondary" onClick={handleShowReportClick}>
          Show Comparison Report
        </button>
      </Link>
    )}
    {errorMessage && (
      <div style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div> // Display error message above submit button
    )}
  </div>
        </div>
      </div>
    </div>
  );
};

export default DifferentProductModal;
