import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const QuotationModal = ({ showModal, handleCloseModal, FinalComparisionId }) => {
  const [selectedBrands, setSelectedBrands] = useState([]); // Array to hold selected brand IDs
  const [brands, setBrands] = useState([]); // Brands fetched from API
  const [Msg, setMsg] = useState('')
  const [quotationCreated, setQuotationCreated] = useState(false); // State to track if quotation is created

  const navigate = useNavigate();
  const em_id = sessionStorage.getItem('em_id');
  console.log('em_id', em_id);
  

  useEffect(() => {
    const em_id = sessionStorage.getItem('em_id'); 
    if (!em_id) {
      navigate('/');
    }
  }, [navigate]);

  console.log('FinalComparisionIdInModal', FinalComparisionId);

  // Function to fetch brands from the API
  const getBrands = async () => {
    try {
      const brandsRes = await axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getFinalQuoteBrandDetails', {
        one: FinalComparisionId,
        authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
      });

      const brandsWithId = brandsRes.data.map(brand => ({
        id: brand.btqc_brand_id,
        name: brand.btb_brand_name,
      }));
      setBrands(brandsWithId);
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  // Function to handle brand checkbox selection
  const handleBrandSelection = (brandId) => {
    setSelectedBrands(prevSelectedBrands => {
      if (prevSelectedBrands.includes(brandId)) {
        return prevSelectedBrands.filter(id => id !== brandId);
      }
      return [...prevSelectedBrands, brandId];
    });
  };

  // Fetch brands when component mounts
  useEffect(() => {
    getBrands();
  }, []);

  // Handle the creation of a quotation
  // const handleCreateQuotation = async () => {
  //   try {
  //     // Make sure we have selected brands
  //     if (selectedBrands.length === 0) {
  //       setMsg("Please select at least one brand.");
  //       return;
  //     }
  
  //     // Sending the selected brands to the API to create the final quotation
  //     const response = await axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/setFinalQuotDetails', {
  //       one: em_id,  // Assuming '1' is the user ID or some other identifier
  //       two: FinalComparisionId, // Use the FinalComparisionId prop as part of the request
  //       three: selectedBrands.join(','), // Join the selected brand IDs with commas
  //       authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"  // API key
  //     });
  
  //     console.log('Quotation Created:', response.data);
  
  //     // Check for success response
  //     if (response.data.includes('Quotation processed successfully.')) {
  //       setMsg('Quotation Generated Successfully.');
  //       setQuotationCreated(true); // Enable "Show Quotation" button
  
  //       const quotationIdMatch = response.data.match(/Quotation ID: (\d+)/);
  //       const quotationId = quotationIdMatch ? quotationIdMatch[1] : null;
  //       console.log('quotationIdddd', quotationId);
  //       if (quotationId) {
  //         sessionStorage.setItem('setquotationId', quotationId);
  //       }
  //     } 
  //     // Check for specific error response about duplicate Quotation ID
  //     else if (response.data.includes('Failed: Quotation with the same ID exists')) {
  //       const errorMessageMatch = response.data.match(/Failed: (.*)/);
  //       const errorMessage = errorMessageMatch ? errorMessageMatch[1] : 'Unknown error';
  //       setMsg(errorMessage); // Display the extracted error message
  //     } 
  //     // General error fallback
  //     else {
  //       setMsg('An error occurred during quotation generation');
  //       setQuotationCreated(false); // Disable "Show Quotation" button
  //     }
  
  //   } catch (error) {
  //     console.error('Error creating quotation:', error);
  //     alert("There was an error creating the quotation. Please try again.");
  //   }
  // };
  const handleCreateQuotation = async () => {
    try {
      // Make sure we have selected brands
      if (selectedBrands.length === 0) {
        setMsg("Please select at least one brand.");
        return;
      }
  
      // Sending the selected brands to the API to create the final quotation
      const response = await axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/setFinalQuotDetails', {
        one: em_id,  // Assuming '1' is the user ID or some other identifier
        two: FinalComparisionId, // Use the FinalComparisionId prop as part of the request
        three: selectedBrands.join(','), // Join the selected brand IDs with commas
        authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"  // API key
      });
  
      console.log('Quotation Created:', response.data); // Log the response to see the full response data
  
      // Check for success response
      if (response.data.includes('Quotation processed successfully.')) {
        setMsg('Quotation Generated Successfully.');
        setQuotationCreated(true); // Enable "Show Quotation" button
  
        const quotationIdMatch = response.data.match(/Quotation ID: (\d+)/);
        const quotationId = quotationIdMatch ? quotationIdMatch[1] : null;
        console.log('quotationIdddd', quotationId);
        if (quotationId) {
          sessionStorage.setItem('setquotationId', quotationId);
        }
      } 
      // Check for specific error response about duplicate Quotation ID
      else if (response.data.includes('Failed: Quotation with the same ID exists')) {
        const errorMessageMatch = response.data.match(/Failed: (.*)/);
        const errorMessage = errorMessageMatch ? errorMessageMatch[1] : 'Unknown error';
        setMsg(errorMessage); // Display the extracted error message
      } 
      // General error fallback
      else {
        setMsg('An error occurred during quotation generation. Response did not match expected format.');
        setQuotationCreated(false); // Disable "Show Quotation" button
      }
  
    } catch (error) {
      console.error('Error creating quotation:', error);
      alert("There was an error creating the quotation. Please try again.");
    }
  };
  
  // Redirect function for "Show Quotation" button
const handleShowQuotation = () => {
  if (quotationCreated) {
    navigate('/quotation'); // Navigate to the ProductMaster page
  }
};

  console.log('Selected Brands:', selectedBrands); // Logs the selected brand IDs

  return (
    <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '80%', margin: 'auto' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Create Final Quotation</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}></button>
          </div>
          <div className="modal-body">
            <div>
              <b>Select Brands<span style={{ color: 'red' }}>*</span></b>
              <div className="mt-2">
                {brands.map((brand) => (
                  <div key={brand.id} className="form-check" style={{ marginBottom: '0px' }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={`brand-${brand.id}`}
                      checked={selectedBrands.includes(brand.id)}
                      onChange={() => handleBrandSelection(brand.id)}
                    />
                    <label className="form-check-label" htmlFor={`brand-${brand.id}`}>
                      {brand.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleCreateQuotation}
            >
              Create Quotation
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleShowQuotation}
              style={{ display: quotationCreated ? 'inline-block' : 'none' }} // Hide button until quotation is created
              >
              Show Quotation
            </button>

            <p style={{ color: "#198754" }}>{Msg}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotationModal;
