import React, { useState, useEffect } from 'react';
import './Comparison.css';
import axios from 'axios';


const SimilarComparisionReport = () => {
  const [loading, setLoading] = useState(false); // Track loading state

  const [Report, setReport] = useState(''); // State to store the message    
  const [comparisonData, setComparisonData] = useState([]); // Store the API response data
  
  const em_id = sessionStorage.getItem('em_id'); 
  console.log('em_iddddddddddd',em_id);
 
  const ComparisionId = sessionStorage.getItem('ComparisionId');
  console.log("ComparisionIddd",ComparisionId);            // This will log the saved QuoteId
  
  useEffect(() => {
    // Define the async function inside the useEffect and call it
    const fetchData = async () => {
      setLoading(true); // Set loading to true at the start of the process

      try {
        const finalResponse = await axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getQuoteMasterReportDetails', {
          one:ComparisionId,
          authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
        });

        setReport(finalResponse.data);
        const data = finalResponse.data.jsArray;
        setComparisonData(data); // Store the API response data
        console.log("comparisonData", data);
      } catch (error) {
        console.error('Error during the web service calls!', error);
      } finally {
        setLoading(false); // Set loading to false after all API calls are finished
      }
    };

    fetchData(); // Call the fetchData function

  }, [ComparisionId]); // Dependency array to re-run effect when quote_id changes
  // Helper function to group products by both brand and category dynamically
  const groupProductsByBrandAndCategory = (data) => {
    const brands = {};  // Use an empty object to store brands dynamically

    data.forEach((row) => {
      const brandName = row.btb_brand_name;  // Brand name
      const categoryName = row.btc_category_name;  // Category name

      if (!brands[brandName]) {
        brands[brandName] = {};
      }

      if (!brands[brandName][categoryName]) {
        brands[brandName][categoryName] = [];
      }

      brands[brandName][categoryName].push({
        product: row.btp_product_name,
        productCode: row.btp_product_code_no,
        image: row.btp_image1,
        mrp: row.btp_mrp_price,
        discount: row.btp_max_discount,
        salePrice: row.sale_rate,
      });
    });

    return brands;
  };

  const groupedProducts = groupProductsByBrandAndCategory(comparisonData);
  const brandNames = Object.keys(groupedProducts);  // Get all unique brand names

  return (
    <>
      <div className="main">
        <div className='section-btn d-flex' style={{ height: "170px", margin: "auto", alignItems: "end" }}>
          <div className="row justify-content-evenly" style={{ position: "fixed", width: "100%", height: "154px", zIndex: "1000", background: "#ffffff", alignItems: "end", top: "0", margin: "auto" }}>
            {brandNames.map((brand) => (
              <div key={brand} className="col-md-2">
                <h3 className='lg_brand-name' style={{ textAlign: 'center', color: '#000000', background: "rgb(227 227 227)", position: "sticky", marginBottom: "0", margin: "10px", borderRadius: "10px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontSize: "18px" }}>
                  {/* {brand} */}
                </h3>
              </div>
            ))}
          </div>
        </div>

        <div className="comparison-container">
          <div className="row justify-content-evenly">
            {/* Render each brand and its categories */}
            {brandNames.map((brand) => (
              <div key={brand} className="col-md-12 mb-0 d-grid justify-content-center">
                <div style={{ padding: '10px', marginBottom: '20px', borderRadius: '10px' }}>
                  {/* Display the brand name once */}
                  <h3 className="brand-name" style={{ textAlign: 'center', color: '#000000', fontSize: '24px', fontWeight: 'bold' }}>
                    {brand}
                  </h3>

                  {/* Render categories for the current brand */}
                  {Object.keys(groupedProducts[brand]).map((category) => (
                    <div key={category}>
                      <h4 className="sm_brand-name" style={{ textAlign: 'center', color: '#000000' }}>{category}</h4>
                      <h4 className="category-name" style={{ textAlign: 'left', color: '#000000', marginTop: '20px' }}>
      {category}
    </h4>

                      {/* Display products for the current category in rows */}
                      <div className="row justify-content-between">
                        {groupedProducts[brand][category].map((product, index) => (
                          <div key={index} className="col-12 col-md-4 col-lg-3 mb-4"> {/* Adjusted col-md to allow more products in a row */}
                            <div className="card custom-card rounded-lg">
                              <img
                                src={product.image ? `http://192.168.0.139:8965/MI_Applications/BTrading/BrandLogo/${product.image}` : 'https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI='}
                                className="card-img img-fluid"
                                alt={product.product}
                              />
                              <div className="card-body">
                                <h5 className="card-title text-center text-dark">{product.product}</h5>
                                <p className="card-text text-muted"><b>Code: <span>{product.productCode}</span></b></p>
                                <p className="card-text text-primary">MRP: <span>₹{product.mrp}</span></p>
                                <p className="card-text font-weight-bold">Sale Price: <span>₹{product.salePrice}</span></p>
                                <p className="card-text text-success">Discount: <span>{product.discount}%</span></p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        
      </div>
      <button class="Btn">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg sign" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />

                  </svg>
                  <div class="text">Download PDF</div>
              </button>
    </>
  );
};

export default SimilarComparisionReport;
