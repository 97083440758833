import React, { useState, useEffect } from 'react';
import './Comparison.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import SimilarComparisonModal from './SmilarComparisonMOdal';
import ComparisonProductDetailModal from './ComparisonProductDetailModal';

const SimilarProductComparision = () => {
  const [comparisonList, setComparisonList] = useState(
    JSON.parse(sessionStorage.getItem('comparisonList')) || []
  );

  const [infoo, setInfoo] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(false); // Track loading state
  const [message, setMessage] = useState(''); // State to store the message
  const [Report, setReport] = useState(''); // State to store the message    
  const [QuoteId, setQuoteId] = useState(''); // State to store the message
  const [comparisonData, setComparisonData] = useState([]); // Store the API response data
  const [Clearmsg, setClearmsg] = useState(''); // Clear session storage msg
  const comparisonString = comparisonList.join(',');

  const [isModalOpenn1, setIsModalOpenn1] = useState(false); // Manage modal visibility

// Function to open the modal
const handleModalOpenn = () => {
  setIsModalOpenn1(true); // Open modal
};

// Function to close the modal
const handleModalClosee = () => {
  setIsModalOpenn1(false); // Close modal
};

  const em_id = sessionStorage.getItem('em_id'); 
  console.log('em_iddddddddddd',em_id);

  // Function to clear the comparison list from session storage and reset state
  const handleClearComparison = () => {
    sessionStorage.removeItem('comparisonList');
    setComparisonData([]);  // Reset comparisonData to clear the table
    setComparisonList([]);
    setClearmsg("Remove product from comparison successfully.");

    setTimeout(() => {
      setClearmsg('');
    }, 2000); // 2000ms = 2 seconds
  };

  useEffect(() => {
    // Call the web services when the component loads
    const fetchData = async () => {
      setLoading(true); // Set loading to true at the start of the process

      try {
        // First web service call: setComaparisionPoint
        const firstResponse = await axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/setQuoteMasterDetails', {
          one: "",
          two: "",
          three: comparisonString, // Ensure comparisonString is defined
          four: em_id,  //addedby
          authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
        });

        setInfoo(firstResponse.data);
        const parsedData = JSON.parse(firstResponse.data);
        setQuoteId(parsedData.quote_id);
        setMessage("Create Comparison Successfully");

        setTimeout(() => {
          setMessage("");  // Clears the message after 3 seconds
        }, 3000);

        if (parsedData.message === "Quote added successfully") {
          console.log("Quote added successfully, calling the second web service...");

          // Second web service call: setComaparPoint
          const secondResponse = await axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/setCompareMasterDetails', {
            one: parsedData.quote_id,
            authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
          });

          console.log('Second web service response:', secondResponse.data);

          // After the second web service completes successfully, make the final getComaparisionPoint call
          console.log("Both web services completed, now calling the final getComaparisionPoint...");

          const finalResponse = await axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getQuoteMasterReportDetails', {
            one: parsedData.quote_id,
            authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
          });

          setReport(finalResponse.data);
          const data = finalResponse.data.jsArray;
          setComparisonData(data); // Store the API response data
          console.log("comparisonData", finalResponse.data.jsArray);
        }
      } catch (error) {
        console.error('Error during the web service calls!', error);
      } finally {
        setLoading(false); // Set loading to false after all API calls are finished
      }
    };

    fetchData(); // Call the function when the component mounts
  }, []); // Empty dependency array ensures it runs only once when the component mounts

 // Helper function to group products by both brand and category dynamically
 const groupProductsByBrandAndCategory = (data) => {
  const brands = {};  // Use an empty object to store brands dynamically

  data.forEach((row) => {
    const brandName = row.btb_brand_name;  // Brand name
    const categoryName = row.btc_category_name;  // Category name

    if (!brands[brandName]) {
      brands[brandName] = {
        totalSalePrice: 0,  // Initialize total sale price for the brand
        categories: {},  // Categories will be nested here
      };
    }

    if (!brands[brandName].categories[categoryName]) {
      brands[brandName].categories[categoryName] = { products: [], totalSalePrice: 0 };  // Initialize category sale price
    }

    brands[brandName].categories[categoryName].products.push({
      productId: row.btqc_product_id,
      product: row.btp_product_name,
      productCode: row.btp_product_code_no,
      image: row.btp_image1,
      mrp: row.btp_mrp_price,
      discount: row.btp_max_discount,
      salePrice: row.sale_rate,
    });

    // Calculate total sale price for the category
    const salePrice = parseFloat(row.sale_rate);  // Ensure the sale rate is a valid number
    if (!isNaN(salePrice)) {
      brands[brandName].categories[categoryName].totalSalePrice += salePrice;
      brands[brandName].totalSalePrice += salePrice;  // Add to the brand's total sale price
    }
  });

  return brands;
};




  const groupedProducts = groupProductsByBrandAndCategory(comparisonData);
  const brandNames = Object.keys(groupedProducts);  // Get all unique brand names

  // Add the `handleRemoveProduct` function:
  const handleRemoveProduct = (productCode) => {
    // Remove product from comparisonList
    const updatedComparisonList = comparisonList.filter((item) => item !== productCode);
  
    // Update comparisonList state and sessionStorage
    setComparisonList(updatedComparisonList);
    sessionStorage.setItem('comparisonList', JSON.stringify(updatedComparisonList));
  
    // Remove the product from comparisonData
    const updatedComparisonData = comparisonData.filter((product) => product.btqc_product_id !== productCode);
  
    // Update comparisonData in the UI
    setComparisonData(updatedComparisonData);
  };

  //view product details modal
  const [isProductModalVisible, setIsProductModalVisible] = useState(false); // Manage modal visibility
  const [activeProductId, setActiveProductId] = useState(null); // Store active product ID


  const handleViewClick = (productId) => {
    setActiveProductId(productId); // Set the active product's ID
    setIsProductModalVisible(true); // Open the modal
  };


  return (
    <>
      <div className="main">
        <div className='section-btn d-flex' style={{ height: "170px", margin: "auto", alignItems: "end" }}>
          <div className="row justify-content-evenly" style={{ position: "fixed", width: "100%", height: "154px", zIndex: "1000", background: "#ffffff", alignItems: "end", top: "0", margin: "auto" }}>
            {brandNames.map((brand) => (
              <div key={brand} className="col-md-2">
                <h3 className='lg_brand-name' style={{ textAlign: 'center', color: '#000000', background: "rgb(227 227 227)", position: "sticky", marginBottom: "0", margin: "10px", borderRadius: "10px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontSize: "18px" }}>
                  {/* {brand} */}
                </h3>
              </div>
            ))}
          </div>
        </div>

        <div className="comparison-container">
        <div className="row justify-content-evenly">
  {/* Render each brand and its categories */}
  {brandNames.map((brand) => (
    <div key={brand} className="col-md-12 mb-0 d-grid justify-content-center">
      <div style={{ padding: '10px', marginBottom: '20px', borderRadius: '10px' }}>
        {/* Display the brand name once */}
        <h3 className="brand-name" style={{ textAlign: 'center', color: '#000000', fontSize: '24px', fontWeight: 'bold' }}>
          {brand}
        </h3>

        {/* Render categories for the current brand */}
        {Object.keys(groupedProducts[brand].categories).map((category) => (
          <div key={category}>
            <h4 className="sm_brand-name" style={{ textAlign: 'center', color: '#000000' }}>{category}</h4>
            <h4 className="category-name" style={{ textAlign: 'left', color: '#000000', marginTop: '20px' }}>
              {category}
            </h4>

            {/* Display products for the current category in rows */}
            <div className="row justify-content-between">
              {groupedProducts[brand].categories[category].products.map((product, index) => (
                <div key={index} className="col-12 col-md-4 col-lg-3 mb-4">
                  <div className="card custom-card rounded-lg">
                    <img
                      src={product.image ? `http://192.168.0.139:8965/MI_Applications/BTrading/BrandLogo/${product.image}` : 'https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI='}
                      className="card-img img-fluid"
                      alt={product.product}
                    />
                    <div className="card-body">
                      <h5 className="card-title text-center text-dark">{product.product}</h5>
                      <p className="card-text text-muted"><b>Code: <span>{product.productCode}</span></b></p>
                      <p className="card-text text-primary">MRP: <span>₹{product.mrp}</span></p>
                      <p className="card-text font-weight-bold">Sale Price: <span>₹{product.salePrice}</span></p>
                      <p className="card-text text-success">Discount: <span>{product.discount}%</span></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Show total sale price for the category */}
            <div style={{ textAlign: 'right', fontWeight: 'bold', marginTop: '10px' }}>
              Total Sale Price for {category}: ₹{(groupedProducts[brand].categories[category].totalSalePrice || 0).toFixed(2)}
            </div>
          </div>
        ))}

        {/* Show total sale price for the brand (across all categories) */}
        {/* <div style={{ textAlign: 'right', fontWeight: 'bold', marginTop: '20px', borderTop: '2px solid #ccc', paddingTop: '10px' }}>
          Total Sale Price for {brand}: ₹{(groupedProducts[brand].totalSalePrice || 0).toFixed(2)}
        </div> */}
      </div>
    </div>
  ))}
</div>

        </div>

        
      </div>

      <section id="alt-services" class="alt-services section">

<div class="container aos-init aos-animate py-5" data-aos="fade-up" data-aos-delay="100">

    <div class="row gy-4">

        <div class="col-lg-6 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
            <div class="service-item position-relative">
                <div class="img">
                    <img src="img/interior.jpg" class="img-fluid" alt="" />
                </div>
                <div class="details">
                    <span class="stretched-link">
                        <h3>Innovative Interior Concepts</h3>
                    </span>
                    <p>Transform your space with our expert interior design services. We create aesthetically pleasing and functional spaces that reflect your unique style and personality. From conceptualization to execution, our team ensures that every detail is meticulously designed to suit your needs.</p>
                </div>
            </div>
        </div>

        <div class="col-lg-6 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
            <div class="service-item position-relative">
                <div class="img">
                    <img src="img/interior-3.jpg" class="img-fluid" alt="" />
                </div>
                <div class="details">
                    <span class="stretched-link">
                        <h3>Modern Interior Design</h3>
                    </span>
                    <p>Embrace the sleek, sophisticated look of modern interior design. Our designs focus on clean lines, minimalist furniture, and innovative use of space to create a contemporary and timeless atmosphere for your home or office.</p>
                </div>
            </div>
        </div>
    </div>
</div>
</section>


      {/* Add more component btn */}
      <Link to={'/jaq'} type="button"  style={{ margin:"10px"  }}>
<button class="Btn">
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg sign" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
  
</svg>
<div class="text">Add More Components</div>
</button>
</Link>


      <button className="clr-Btn" onClick={handleClearComparison}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x sign" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
        </svg>
        <div className="text">Clear Comparison</div>
      </button>

      <button className="BtnAdd" type="button" onClick={handleModalOpenn}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg sign" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
  </svg>
  <div class="text">Comparision Report</div>
</button>

      {/* Comparison Report Modal */}
      {isModalOpenn1 && <SimilarComparisonModal onClose={handleModalClosee} QuoteId={QuoteId} />}

      {isProductModalVisible && (
  <ComparisonProductDetailModal
    productDetailsId={activeProductId} // Pass the active product ID to the modal
    onClose={() => setIsProductModalVisible(false)} // Close the modal
  />
)}
      
    </>
  );
};

export default SimilarProductComparision;
