import React from 'react'
import { Link } from 'react-router-dom'
import './NavBar.css'

export default function Brands() {
  return (
   <>
   <div className='container'>
   


<h3 className='text-center m-3'>Our Brand And Services</h3>
<div className='container d-flex justify-content-center'>
<div className="row">
      {/* Brands Section */}
      <div className="col-lg-6 text-center">
        <i className="bi bi-asterisk" style={{ fontSize: '50px', color: '#ff7d00' }}></i>
        <h2 className="fw-normal"> Brands</h2>
        <p>
          Explore a curated selection of high-quality bathroom brands. From luxurious fixtures to stylish
          accessories.
        </p>
        <p>
        <Link to="/jaq"  className="nav-link" style={{ fontSize: "20px" }}>
              <button  className="btn btn-info" >Our Brands</button>
            </Link>
        </p>
      </div>

      {/* Services Section */}
      <div className="col-lg-6 text-center">
      <i class="bi bi-boxes" style={{ fontSize: '50px', color: '#ff7d00' }}></i>
        <h2 className="fw-normal">Quotations</h2>
        <p>
          Our expert bathroom designers will work with you to create a space that blends functionality with
          style.
        </p>
        <p>
        <Link to="/finalQuotationGrid" className="nav-link" style={{ fontSize: "20px" }}>
              <button className='btn btn-info'>quotation</button>
            </Link>

        </p>
      </div>

 
    </div>

   </div>
   </div>

   




   
   </>
  )
}
