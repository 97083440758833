import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

export default function LeadComparisonGridReport() {
    const [infoo, setInfoo] = useState([]); // Initialize as an empty array
    const [loading, setLoading] = useState(false); // Track loading state
    const [currentPage, setCurrentPage] = useState(1); // Track the current page
    const itemsPerPage = 50; // Items per page
    const [showPopup, setShowPopup] = useState(false); // To show/hide the confirmation popup
    const [selectedItem, setSelectedItem] = useState(null); // Store selected item for deactivation/activation
    const [isDeactivated, setIsDeactivated] = useState({}); // Track deactivation status of each product

    const [Leads, setLeads] = useState([]);
    const [customers, setcustomers] = useState([]);
    const [selectedCustomer, setselectedCustomer] = useState('');
    const [selectedLead, setselectedLead] = useState('');
    const [CustomerId, setCustomerId] = useState(null); // Category ID for selected category
    const [LeadId, setLeadId] = useState(null); // Brand ID
    const [searchQuery, setSearchQuery] = useState(''); // State for the search query

    const em_id = sessionStorage.getItem('em_id'); 

    const navigate = useNavigate();
    //session manage
    useEffect(() => {
      const em_id = sessionStorage.getItem('em_id'); 
      if (!em_id) {
        navigate('/');
      }
    }, [navigate]);

    // Function to send the POST request and fetch data
    const fetchData = () => {
        setLoading(true); // Start loading before request
        axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getLeadMasterReportDetails', {
            authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
        })
            .then(res => {
                // Ensure res.data is an array before setting it to infoo
                setInfoo(Array.isArray(res.data.jsArray) ? res.data.jsArray : []);
                setLoading(false); // Set loading to false after response
                // console.log(res.data.jsArray);
            })
            .catch(error => {
                setLoading(false); // Set loading to false in case of error
                console.error('There was an error!', error);
            });
    };

    // Fetch data on page load
    useEffect(() => {
        fetchData();
    }, []); // Empty dependency array ensures this runs only once on component mount

    // Calculate the index of the first and last item of the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Get the items to display on the current page
    const currentItems = Array.isArray(infoo) ? infoo.slice(indexOfFirstItem, indexOfLastItem) : [];

    { infoo.length === 0 && !loading && <p>No data available</p> }

    // Change the page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Generate page numbers for pagination
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(infoo.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    // Handle the DeActivate button click
    const handleDeactivateClick = (item) => {
        setSelectedItem(item); // Store selected item for deactivation
        setShowPopup(true); // Show the popup for confirmation
    };

    // Function to fetch Leads details
    const getLeads = async () => {
        try {
            const LeadsRes = await axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getLeadMasterReportDetails', {
                 "authApiKey" : "ytfkenaojjawmbjnbsyyj-vhvbs"
            });

            const LeadsWithId = LeadsRes.data.jsArray.map(lead => ({
                id: lead.leadID,
                name: lead.leadName,
            }));
            setLeads(LeadsWithId);
        } catch (error) {
            console.error('Error fetching Leads:', error);
        }
    };
    useEffect(() => {
        getLeads();
    }, []);

   
    // Fetch Customers on component mount 
    const getcustomers = async () => {
        try {
            const customersRes = await axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getCustomerMasterReportDetails01', {
                authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs" // Replace with your actual API key
            });
            const customersWithId = customersRes.data.jsArray.map(customer => ({
                id: customer.customer_id,
                name: customer.customer_name,
            }));
            setcustomers(customersWithId);
        } catch (error) {
            console.error('Error fetching customers:', error);
        }
    };
    useEffect(() => {
        getcustomers(); // Fetch customers when the component mounts
    }, []);

    // Handle the Confirm Deactivation/Activation
    const handleConfirmDeactivate = () => {
        if (selectedItem) {
            axios.post('https://midrserver.co.in:8289/trading_application/rest/menuService/setDeactivateProductMasterDetails', {
                one: selectedItem.btp_product_id,
                two: em_id, // Assuming "1" means deactivate; adapt based on backend logic
                authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
            })
                .then(response => {
                    if (response.data === "Success" && selectedItem.btp_product_status === "Active") {
                        setIsDeactivated(prevState => ({
                            ...prevState,
                            [selectedItem.btp_product_id]: true // Mark as deactivated
                        }));
                    } else if (response.data === "Product activated successfully" && selectedItem.btp_product_status === "Inactive") {
                        setIsDeactivated(prevState => ({
                            ...prevState,
                            [selectedItem.btp_product_id]: false // Mark as active
                        }));
                    }
                    setShowPopup(false); // Close the popup after success
                })
                .catch(error => {
                    console.error('Deactivation failed:', error);
                    setShowPopup(false); // Close the popup on failure
                });
        }
    };

    // Handle Product Click for navigating to the product page
    const handleNameClick = (item) => {
        sessionStorage.setItem('selectedCompLeadId', item.leadID); // Store product ID


        navigate('/leadCompareMasterReport'); // Navigate to the ProductMaster page
    };

    //Searching functinality 
    // Function to search products by name
const searchByName = (name) => {
    setLoading(true); // Start loading before request
    axios.post('https://midrserver.co.in:8289/trading_application/rest/menuService/getProductMasterReportDetails', {
        two: name,
        authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs",
      //  name: name  // Pass the name as a filter
    })
    .then(res => {
        setInfoo(Array.isArray(res.data.jsArray) ? res.data.jsArray : []);
        setLoading(false); // Set loading to false after response
    })
    .catch(error => {
        setLoading(false); // Set loading to false in case of error
        console.error('Error searching by name:', error);
    });
};

// Function to search products by category
const searchByCustomer = (CustomerId) => {
    setLoading(true); // Start loading before request
    axios.post('https://midrserver.co.in:8289/trading_application/rest/menuService/getCategoryDropdownDetails', {
        one: CustomerId , // Pass the selected category ID as a filter
        authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
       
    })
    .then(res => {
        setInfoo(Array.isArray(res.data) ? res.data : []);
        setLoading(false); // Set loading to false after response
    })
    .catch(error => {
        setLoading(false); // Set loading to false in case of error
        console.error('Error searching by category:', error);
    });
};

// Function to search products by brand
const searchByLead = (LeadId) => {
    console.log('LeadIdddddd',LeadId);
    
    setLoading(true); // Start loading before request
    axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getLeadMasterReportDetails', {
         one: LeadId , // Pass the selected category ID as a filter
        authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
       
    })
    .then(res => {
        setInfoo(Array.isArray(res.data) ? res.data : []);
        setLoading(false); // Set loading to false after response
    })
    .catch(error => {
        setLoading(false); // Set loading to false in case of error
        console.error('Error searching by brand:', error);
    });
};

// Handle search input change
const handleSearchChange = (e) => {
    const searchQuery = e.target.value;  // Get the search query
    setSearchQuery(searchQuery);  // Update the search query state
    if (searchQuery) {
        searchByName(searchQuery);  // Call searchByName API if the user is typing a name
    } else {
        fetchData();  // If the search query is cleared, fetch all data again
    }
};

// Handle customer change
const handleCustomerChange = (customer) => {
    setselectedCustomer(customer.name);  // Set the selected category
    setCustomerId(customer.id);  // Set the category ID
    searchByCustomer(customer);  // Call searchByCustomer API when category is changed
    console.log('customer',customer);
    
};

// Handle lead change
const handleLeadChange = (lead) => {
    setselectedLead(lead.name);  // Set the selected brand
    searchByLead(lead);  // Call searchByLead API when brand is changed
    console.log('leadIddd',lead); 
};


    return (
        <div className="container mb-5" >
            <div className='container' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <p style={{ color: "#000000", textAlign: "center", flex: 1, fontSize: "23px",fontWeight:"700" }}>Quotation Grid Report </p>
                
            </div>
            {/* input fields start here */}
            <p style={{ textAlign: "left", color: "gray" ,marginTop:"30px",marginBottom:"0px" }}>Serach report by QuoteId, Lead or Customer Name</p>

            <div className="row d-flex justify-content-evenly">
                {/* Lead Dropdown */}
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-1">
                    {/* <label><b style={{ color: "#FFC300" }}>Lead Name</b></label> */}
                    <div className="btn-group">
                        <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ width: "250px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', background: "#f1f1f1" }}
                        >
                            {selectedLead || 'Select Lead'}
                        </button>
                        <ul className="dropdown-menu" style={{ maxHeight: "200px", overflowY: "auto" }}>
                            {Leads.map((Lead) => (
                                <li key={Lead.id}>
                                    <button
                                        type="button"
                                        className="dropdown-item"
                                       // onChange={()=>{handleLeadChange(brand.id)}}
                                        onClick={() => {
                                            setselectedLead(Lead.name);
                                            setLeadId(Lead.id);
                                            handleLeadChange(Lead.id)
                                        }}
                                        style={{ width: "235px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
                                        title={Lead.name} // Add the title attribute here
                                    >
                                        {Lead.name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>

                
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-1">
      <Link to="/gridQuotation">
        <button type="button" className="btn btn-outline-secondary">
          Customer Report
        </button>
      </Link>
    </div>

            </div>



            <div style={{ overflowX: "auto", maxHeight: "100%", overflowY: "auto", scrollbarWidth: "none" }}>
                {infoo.length > 0 && (
                    <div>
                        <table className="table table-bordered" style={{ borderRadius: "10px", background: "rgb(233, 231, 232)", border: "2px solid rgb(224, 174, 174)", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", width: "100%" }}>
                            <thead>
                                <tr>
                                    <th style={{ fontSize: "15px" }} >No.</th>
                                    <th style={{ fontSize: "15px" }} >Name</th>
                                    <th style={{ fontSize: "15px" }} >Email</th>
                                    <th style={{ fontSize: "15px" }} >Contact</th>
                                    <th style={{ fontSize: "15px" }} >Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>{indexOfFirstItem + index + 1}</td>
                                        <td
                                            onClick={() => handleNameClick(item)}
                                            style={{ cursor: 'pointer' }}
                                            title={item.leadName}
                                        >
                                            {item.leadName && item.leadName.length > 20
                                                ? item.leadName.slice(0, 20) + '...'
                                                : item.leadName || 'No Name Available'}
                                        </td>
                                        <td>{item.leadMail}</td>
                                        <td>{item.leadContact}</td>
                                        <td>{item.leadStatus}</td>
                                        

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            {infoo.length === 0 && !loading && <p>No data available</p>}

            {/* Pagination controls */}
            <nav className='mt-2 mb-2'>
                <ul className="pagination justify-content-end">
                    {pageNumbers.map(number => (
                        <li key={number} className="page-item">
                            <button
                                onClick={() => paginate(number)}
                                className={`page-link ${currentPage === number ? 'active' : ''}`}
                            >
                                {number}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>

            {/* Confirmation Popup */}
            {showPopup && (
                <div className="modal" tabIndex="-1" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Deactivation</h5>
                                <button type="button" className="btn-close" onClick={() => setShowPopup(false)}></button>
                            </div>
                            <div className="modal-body">
                                <p>Do you really want to {isDeactivated[selectedItem.btp_product_id] ? "activate" : "deactivate"} this product?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowPopup(false)}>Cancel</button>
                                <button type="button" className="btn btn-primary" onClick={handleConfirmDeactivate}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
