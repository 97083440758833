import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './CustomerMasterDetails.css';

export default function FinalQuotationLeadReport() {
  const [product, setProduct] = useState(null);  // State to store product details
  const [loading, setLoading] = useState(true);  // Loading state
  const [error, setError] = useState(null);      // Error state
  const [quotations, setQuotations] = useState([]); // State to store quotations
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const itemsPerPage = 10; // Items per page
  const[Message,setMessage]=useState('')
  const navigate = useNavigate(); // useNavigate to navigate between pages
 // Track the hovered item index
 const [hoveredIndex, setHoveredIndex] = useState(null);

 const handleMouseEnter = (index) => setHoveredIndex(index);
 const handleMouseLeave = () => setHoveredIndex(null);
 
  //session manage
  useEffect(() => {
    const em_id = sessionStorage.getItem('em_id');
    if (!em_id) {
        navigate('/');
    }
}, [navigate]);

  // Retrieve the product ID from sessionStorage
  const LeadId = sessionStorage.getItem('selectedQuoteLeadId');
  console.log('LeadId', LeadId);

  useEffect(() => {
    // fetch webservice to get lead details.
    axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getLeadMasterDetails', {
      one:LeadId,
      authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs",
    })
      .then(res => {
        if (res.data && res.data&& res.data.length > 0) {
          setProduct(res.data[0]);  // Assuming the response contains the product data
          console.log(res.data[0].leadName);
          sessionStorage.setItem('selectedQuotationLeadName', res.data[0].leadName); // Store product ID
          sessionStorage.setItem('selectedQuotationContact1', res.data[0].leadContact); // Store product ID
          sessionStorage.setItem('selectedQuotationContact2', res.data[0].leadAltContact); // Store product ID
          sessionStorage.setItem('selectedQuotationEmail', res.data[0].leadMail); // Store product ID
      
        } else {
          setError('No product data found');
        }
        setLoading(false);
      })
      .catch(err => {
        setError('Error fetching product details');
        setLoading(false);
      });
  }, []); // Fetch product details on component mount

  useEffect(() => {
    // Fetch quotations Details using the lead ID
    axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getFinalQuoteReportDetailsLead', {
      three: LeadId,
      authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs",
    })
      .then(res => {
        if (res.data.jsArray && res.data.jsArray.length > 0) {
          setQuotations(res.data.jsArray);  // Assuming the response contains the quotations
          console.log(res.data.jsArray);
          
        }
        else if (res.data && res.data.length === 0) {
          setMessage("No Single Comparison Assign to this customer");  // Assuming the response contains the quotations
          
        } else {
          setError('No quotations found');
        }
        setLoading(false);
      })
      .catch(err => {
        setError('Error fetching quotations');
        setLoading(false);
      });
  }, []); // Fetch quotations on component mount

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginate = (items, page, itemsPerPage) => {
    const startIndex = (page - 1) * itemsPerPage;
    return items.slice(startIndex, startIndex + itemsPerPage);
  };

  const totalPages = Math.ceil(quotations.length / itemsPerPage);

  // Pagination items to display for the current page
  const currentQuotations = paginate(quotations, currentPage, itemsPerPage);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Handle Product Click for navigating to the product page
  const handleNameClick = (quotation) => {
    sessionStorage.setItem('selectedFinalQuotationId', quotation.quot_quotation_id); // Store product ID
    console.log('selectedFinalQuotationId',quotation.quot_quotation_id);
    navigate('/finalquotationReport'); // Navigate to the ProductMaster page
  };

  return (
    <div className="container" style={{
      background: "white",
      borderRadius: "15px",
      padding: "5px",
      width: "100%",
      maxHeight: "auto",  // Ensure the height adapts to content
      position: "relative",
      zIndex: "1",
      scrollbarWidth: "none",
      marginBottom: "0px"  // Add space at the bottom to avoid footer overlap
    }}>
      <div style={{ marginTop: "70px" }}>
        {product ? (
          <div>
            {/* Product Information */}
            <div className="row" style={{ borderRadius: "15px" }}>
              <h2 style={{ color: "#4CAF50", textAlign: "center" }}>{product.leadName}</h2>
              <div className="row"style={{marginTop:"-10px" }}>
                <div className="col col-lg-8 col-md-8 col-12">
                  <div className="d-flex justify-content-left">
                    <strong>Lead Name:</strong> <span>&nbsp;{product.leadName}</span>
                  </div>
                  <div className="d-flex justify-content-left">
                    <strong>Contact:</strong> <span>&nbsp;{product.leadContact}</span>
                  </div>
                  <div className="d-flex justify-content-left">
                    <strong>Contact 2:</strong> <span>&nbsp;{product.leadAltContact}</span>
                  </div> 
                  <div className="d-flex justify-content-left">
                    <strong>Email Id:</strong> <span>&nbsp;{product.leadMail}</span>
                  </div>
                  <div className="d-flex justify-content-left">
                    <strong>Status:</strong> <span>&nbsp;{product.leadStatus}</span>
                  </div>
                </div>
                <div className="col col-lg-4 col-md-4 col-12"></div>
                
              </div>
            </div>

            <div>
              <h4 style={{ marginTop: "-5px", textAlign: "center", color: "#4CAF50" }}>
                Customer Comparison Grid Report
              </h4>
              <table className="quotation-table" style={{marginTop:"-4px"}}>
                <thead>
                  <tr>
                    <th>Quotation Name</th>
                    <th>Total Amount</th>
                    <th>Date</th>
                    <th>Status</th>
                    {/* <th>Status</th> */}
                  </tr>
                </thead>
                <tbody>
                  <p style={{textAlign:"left", color:"#4CAF50",fontSize:"18px"}}> {Message}</p>
                  {currentQuotations.map((quotation, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'even-row' : ''}>
                      <td
                        style={{ cursor: 'pointer',color:"rgb(14 91 159)",
                          textDecoration: hoveredIndex === index ? 'underline' : 'none', }}
                        onClick={() => handleNameClick(quotation)}
                        onMouseEnter={() => handleMouseEnter(index)} // Set hover index on mouse enter
                        onMouseLeave={handleMouseLeave} // Reset hover index on mouse leave
                      >
                        QTN-{quotation.quot_quotation_id}
                      </td>
                      <td>{quotation.quot_total_amount}</td>
                      <td>{quotation.quot_updated_date}</td>
                      <td>{quotation.quot_status}</td>
                      {/* <td>{quotation.btcust_customer_name}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination Controls */}
            <div className="pagination-controls">
              <button
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                First
              </button>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
              <button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                Last
              </button>
            </div>

            <div className="row d-flex justify-content-center">
              <Link
                to={'/finalQuotationGrid'}
                className="btn btn-outline-success"
                type="button"
                style={{ width: "300px", height: "40px", margin: "5px" }}
              >
                Quotation Grid Report
              </Link>
            </div>
          </div>
        ) : (
          <p>No product details available</p>
        )}
      </div>
    </div>
  );
}
