import React, { useState, useEffect } from 'react';
import './Comparison.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import InfoCard from './InfoCard';

import ComparisonProductDetailModal from './ComparisonProductDetailModal';
import DifferentProductModal from './DifferentProductmodal';


const DifferentProductComparison = () => {
    const [comparisonList, setComparisonList] = useState(
        JSON.parse(sessionStorage.getItem('comparisonList')) || []
    );

    const [infoo, setInfoo] = useState([]); // Initialize as an empty array
    const [loading, setLoading] = useState(false); // Track loading state
    const [message, setMessage] = useState(''); // State to store the message
    const [Report, setReport] = useState(''); // State to store the message    
    const [QuoteId, setQuoteId] = useState(''); // State to store the message
    const [comparisonData, setComparisonData] = useState([]); // Store the API response data
    const [Clearmsg, setClearmsg] = useState(''); // Clear session storage msg
    const comparisonString = comparisonList.join(',');

    const [isModalOpen, setIsModalOpen] = useState(false); // Manage modal visibility
    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    console.log('comparisonStringggg', comparisonString);

    //view product details modal
    const [isProductModalVisible, setIsProductModalVisible] = useState(false); // Manage modal visibility
    const [activeProductId, setActiveProductId] = useState(null); // Store active product ID

    const handleViewClick = (productId) => {
        setActiveProductId(productId); // Set the active product's ID
        setIsProductModalVisible(true); // Open the modal
    };



    // Modify the handleRemoveProduct function to use the correct product identifier
    const handleRemoveProduct = (productId) => {
        // Remove product from comparisonList
        const updatedComparisonList = comparisonList.filter((item) => item !== productId);

        // Update comparisonList state and sessionStorage
        setComparisonList(updatedComparisonList);
        sessionStorage.setItem('comparisonList', JSON.stringify(updatedComparisonList));

        // Remove the product from comparisonData
        const updatedComparisonData = comparisonData.filter((product) => product.btqc_product_id !== productId);

        // Update comparisonData in the UI
        setComparisonData(updatedComparisonData);
    };




    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const em_id = sessionStorage.getItem('em_id');
    console.log('em_iddddddddddd', em_id)

    // Function to clear the comparison list from session storage and reset state
    const handleClearComparison = () => {
        sessionStorage.removeItem('comparisonList');
        setComparisonData([]);  // Reset comparisonData to clear the table
        setComparisonList([]);
        setClearmsg("Remove product from comparison successfully.");

        setTimeout(() => {
            setClearmsg('');
        }, 2000); // 2000ms = 2 seconds
    };


    useEffect(() => {
        if (comparisonList.length > 0) {
            // Call the web service when comparisonList is updated
            const fetchData = async () => {
                setLoading(true);

                try {
                    // Web service call with updated comparisonList
                    const comparisonString = comparisonList.join(',');

                    const response = await axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/setQuoteMasterDetails', {
                        one: "",
                        two: "",
                        three: comparisonString, // Send updated comparisonList
                        four: em_id,
                        authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
                    });

                    // Handle the response and set the state accordingly
                    setInfoo(response.data);
                    const parsedData = JSON.parse(response.data);
                    setQuoteId(parsedData.quote_id);
                    setMessage("Create Comparison Successfully");

                    setTimeout(() => {
                        setMessage("");
                    }, 3000);

                    if (parsedData.message === "Quote added successfully") {
                        // Continue with the other web service calls after this one
                        const secondResponse = await axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/setCompareMasterDetails', {
                            one: parsedData.quote_id,
                            two: "FALSE",
                            authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
                        });

                        const finalResponse = await axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getQuoteMasterReportDetails', {
                            one: parsedData.quote_id,
                            authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
                        });

                        setReport(finalResponse.data);
                        const data = finalResponse.data.jsArray;
                        setComparisonData(data); // Update comparisonData with the new data
                    }
                } catch (error) {
                    console.error('Error during the web service calls!', error);
                } finally {
                    setLoading(false); // Reset loading state
                }
            };

            fetchData(); // Trigger the API call
        }
    }, [comparisonList]); // Only trigger when comparisonList changes

    console.log('comparisonList', comparisonList)

    // Helper function to group products by brand dynamically


    // Helper function to group products by brand dynamically
    const groupProductsByBrand = (data) => {
        const brands = {};  // Use an empty object to store brands dynamically

        data.forEach((row) => {
            const brandName = row.btb_brand_name;  // Get brand name dynamically
            if (!brands[brandName]) {
                brands[brandName] = [];
            }

            brands[brandName].push({
                productId: row.btqc_product_id,
                product: row.btp_product_name,
                productCode: row.btp_product_code_no,
                image: row.btp_image1,
                mrp: row.btp_mrp_price,
                discount: row.btp_max_discount,
                salePrice: row.sale_rate,
            });
        });

        return brands;
    };

    return (
        < >
            <div className='main' >

                <div className="comparison-container" >
                <div className="row justify-content-center" style={{ marginTop: "84px",textAlign: "center" }}>
  <h2 style={{ textAlign: "center", fontSize: "24px" }}>Combine Brand Products</h2>
</div>

                    <div className="row justify-content-start" >


                        {/* Display all products as individual cards */}
                        {comparisonData.map((product, index) => (
                            <div key={index} className="col-md-3 mb-0 d-grid justify-content-center">
                                <div style={{ padding: '10px', marginBottom: '20px', borderRadius: '10px' }}>
                                    {/* <h3 className='sm_brand-name' style={{ textAlign: 'center', color: '#000000', position: "sticky" }}>
            
            {product.btp_product_name}
          </h3> */}

                                    {/* Display individual product */}
                                    <div className="card custom-card rounded-lg mb-4">
                                        <img
                                            src={product.btp_image1 ? `http://192.168.0.139:8965/MI_Applications/BTrading/BrandLogo/${product.btp_image1}` : 'https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI='}
                                            className="card-img img-fluid"
                                            alt={product.btp_product_name}
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title text-center text-dark" title={product.btp_product_name}>{product.btp_product_name}</h5>
                                            <p className="card-text text-muted"><b>Code: <span>{product.btp_product_code_no}</span></b></p>
                                            <p className="card-text text-primary">MRP: <span>₹{product.btp_mrp_price}</span></p>
                                            <p className="card-text font-weight-bold">Sale Price: <span>₹{product.sale_rate}</span></p>
                                            <p className="card-text text-success">Discount: <span>{product.btp_max_discount}%</span></p>

                                            <a
                                                className="bi bi-x-circle"
                                                onClick={() => handleRemoveProduct(product.btqc_product_id)} // Trigger removal function
                                                style={{ position: 'absolute', top: '10px', right: '10px', color: "Black" }}
                                            >
                                            </a>

                                            <div style={{ marginTop: "0px", marginBottom: "0px", display: "flex", justifyContent: "center" }}>
                                                <button className="btn btn-outline-secondary btn-sm" style={{ marginBottom: "3px" }}
                                                    onClick={() => handleViewClick(product.btqc_product_id)} // Pass the product code to the view function
                                                >View</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Final Total Row */}
                <div className="row justify-content-center mt-4">
                    <h5 className="card-title text-center text-dark">Total Amount Of All Products - ₹{
                        comparisonData.reduce((total, product) => total + parseFloat(product.sale_rate || 0), 0).toFixed(2)
                    }</h5>
                    <p className="card-text font-weight-bold">
                    </p>

                </div>


                {/* ------------------------------------------------------------ */}
                <section id="alt-services" class="alt-services section">

                    <div class="container aos-init aos-animate py-5" data-aos="fade-up" data-aos-delay="100">

                        <div class="row gy-4">

                            <div class="col-lg-6 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
                                <div class="service-item position-relative">
                                    <div class="img">
                                        <img src="img/interior.jpg" class="img-fluid" alt="" />
                                    </div>
                                    <div class="details">
                                        <span class="stretched-link">
                                            <h3>Innovative Interior Concepts</h3>
                                        </span>
                                        <p>Transform your space with our expert interior design services. We create aesthetically pleasing and functional spaces that reflect your unique style and personality. From conceptualization to execution, our team ensures that every detail is meticulously designed to suit your needs.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
                                <div class="service-item position-relative">
                                    <div class="img">
                                        <img src="img/interior-3.jpg" class="img-fluid" alt="" />
                                    </div>
                                    <div class="details">
                                        <span class="stretched-link">
                                            <h3>Modern Interior Design</h3>
                                        </span>
                                        <p>Embrace the sleek, sophisticated look of modern interior design. Our designs focus on clean lines, minimalist furniture, and innovative use of space to create a contemporary and timeless atmosphere for your home or office.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>


            <Link to={'/jaq'} type="button" style={{ margin: "10px" }}>
                <button class="Btn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg sign" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />

                    </svg>
                    <div class="text">Add More Components</div>
                </button>
            </Link>


            <button class="clr-Btn" onClick={handleClearComparison}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x sign" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                </svg>
                <div class="text" >Clear Comparison</div>
            </button>



            <button class="BtnAdd" type="button" onClick={handleModalOpen}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg sign" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />

                </svg>
                <div class="text">Comparision Report</div>
            </button>

            <DifferentProductModal isOpen={isModalOpen} onClose={handleModalClose} QuoteId={QuoteId} />

            {isProductModalVisible && (
                <ComparisonProductDetailModal
                    productDetailsId={activeProductId} // Pass the active product ID to the modal
                    onClose={() => setIsProductModalVisible(false)} // Close the modal

                />
            )}



            <InfoCard />
        </>
    );
};

export default DifferentProductComparison;
