import React, { useState, useEffect } from 'react';
import { Document, Page, Text, View, Image, StyleSheet, Font, pdf } from '@react-pdf/renderer';
import axios from 'axios';
import './Comparison.css';
import { Link } from 'react-router-dom';
import InfoCard from './InfoCard';

const ComparisionReport = () => {
  const [comparisonList, setComparisonList] = useState(
    JSON.parse(sessionStorage.getItem('comparisonList')) || []
  );
  const [infoo, setInfoo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Amount,setAmount]=useState([])
  const [Report, setReport] = useState('');
  const[quantity,setQuantity]=useState('')
 
  const [comparisonData, setComparisonData] = useState([]);
 
  const em_id = sessionStorage.getItem('em_id');
  console.log('em_id', em_id);

  const ComparisionId = sessionStorage.getItem('ComparisionId');
  console.log("ComparisionIddd",ComparisionId);            // This will log the saved QuoteId
  

  
  

  const fetchData = () => {
    setLoading(true);
    axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getAssignComapreMasterDetails', {
        one:ComparisionId,
       // one:"1",
        authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
    })
        .then(res => {
            setInfoo(Array.isArray(res.data) ? res.data : []);
            setLoading(false);
            setReport(res.data);
          console.log(res.data);
          const data = res.data;
          setComparisonData(data); // Store the API response data
          console.log("comparisonData", res.data);
        })
        .catch(error => {
            setLoading(false);
            console.error('There was an error!', error);
        });
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  
  const fetchAmount = () => {
    axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getComparisonPricing', {
        one:ComparisionId,
        authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
    })
        .then(res => {
            setAmount(Array.isArray(res.data) ? res.data : []);   
           setQuantity(res.data[0].bca_quantity) 
            console.log(res.data);
         
        })
        .catch(error => {
            console.error('There was an error!', error);
        });
  };
  
  useEffect(() => {
    fetchAmount();
  }, []);


  const groupProductsByBrand = (data) => {
    const brands = {};  

    data.forEach((row) => {
      const brandName = row.btb_brand_name;
      if (!brands[brandName]) {
        brands[brandName] = [];
      }

      brands[brandName].push({
        product: row.btp_product_name,
        productCode: row.btp_product_code_no,
        image: row.btp_image1,
        mrp: row.btp_mrp_price,
        discount: row.btp_max_discount,
        salePrice: row.sale_rate,
      });
    });

    return brands;
  };

  const groupedProducts = groupProductsByBrand(comparisonData);
  const brandNames = Object.keys(groupedProducts);



// Function to truncate text with ellipsis
const truncateText = (text, maxLength) => text.length > maxLength ? text.substring(0, maxLength) + '...' : text;



  // Function to convert image to Base64
const imageToBase64 = async (imageUrl) => {
  try {
    // Fetch the image from the provided URL
    const res = await fetch(imageUrl);
    
    // Check if the response was successful (status code 200)
    if (!res.ok) {
      throw new Error(`Failed to fetch image: ${res.status} ${res.statusText}`);
    }

    // Get the Content-Type header
    const contentType = res.headers.get("Content-Type");

    // Check if the Content-Type is either PNG or JPEG
    if (!contentType || (contentType !== "image/png" && !contentType.includes("image/jpeg"))) {
      throw new Error("The image must be in PNG or JPEG format.");
    }

    // Convert the image response to a Blob object
    const blob = await res.blob();

    // Convert the Blob object to a Base64 string
    const base64Image = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log(`Image from ${imageUrl} converted to base64 successfully.`);
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

    // Return the Base64-encoded image string
    return base64Image;
  } catch (error) {
    console.error("Error converting image to base64:", error.message);
    return null; // Return null if an error occurred
  }
};

// Function to generate PDF with image and product details
const generatePDF = async () => {
  // Convert all image URLs to base64
  const updatedProducts = await Promise.all(
    Object.entries(groupedProducts).map(async ([brand, brandGroup]) => {
      return await Promise.all(
        brandGroup.map(async (product) => {
          // Check if product.image exists, if not, use a fallback image URL
          const imageUrl = product.image 
            ? `http://192.168.0.139:8965/MI_Applications/BTrading/BrandLogo/${product.image}`
            : 'https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI=';

          // Convert image to base64
          const base64Image = await imageToBase64(imageUrl);

          return {
            ...product,
            base64Image: base64Image || imageUrl, // Fallback to the original image URL if base64 conversion fails
          };
        })
      );
    })
  );

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.pageHead}>
          <Text style={styles.headerText}>Comparison Report</Text>
        </View>

        {/* Customer Information Section */}
        {infoo && infoo.length > 0 && (
          <View style={styles.cust_section}>
            <View style={styles.information_container}>
              <Text style={styles.subHeaderText}>
                Customer Name - {infoo[0].btcust_customer_name} {infoo[0].btl_lead_name}
              </Text>
              <Text style={styles.infoText}>
                Contact Number - {infoo[0].btcust_contact_number} {infoo[0].btl_contact_number}
              </Text>
              <Text style={styles.infoText}>
                E-MailID - {infoo[0].btl_email} {infoo[0].btcust_email}
              </Text>
            </View>
          </View>
        )}

        {/* Product Comparison Section */}
        <View style={styles.productsContainer}>
          {Object.entries(groupedProducts).map(([brand, brandGroup], brandIndex) => (
            <View key={brandIndex} style={styles.brandSection}>
              <Text style={styles.brandTitle}>{brand}</Text>

              <View style={styles.productsList}>
                {updatedProducts[brandIndex].map((product, index) => (
                  <View key={index} style={styles.productCard}>
                    <Image
                      style={styles.productImage}
                      src={product.base64Image} // Use base64 image or the original URL as fallback
                    />
                    <Text style={styles.productName}>{truncateText(product.product, 15)}</Text>
                    <View style={styles.productDetails}>
                      <Text style={styles.label}>Code:</Text>
                      <Text style={styles.value}>{product.productCode}</Text>
                    </View>
                    <View style={styles.productDetails}>
                      <Text style={styles.MRPlabel}>MRP:</Text>
                      <Text style={styles.MRPvalue}>₹{product.mrp}</Text>
                    </View>
                    <View style={styles.productDetails}>
                      <Text style={styles.label}>Sale Price:</Text>
                      <Text style={styles.value}>₹{product.salePrice}</Text>
                    </View>
                    <View style={styles.productDetails}>
                      <Text style={styles.label}>Discount:</Text>
                      <Text style={styles.discountValue}>{product.discount}%</Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          ))}
        </View>

        {/* Total Price for each Brand Section */}
        <View style={styles.totalContainer}>
          {Object.entries(groupedProducts).map(([brand, brandGroup]) => (
            <Text key={brand} style={styles.totalText}>
              Total for {brand}: ₹
              {brandGroup
                .reduce((total, product) => total + parseFloat(product.salePrice || 0), 0)
                .toFixed(2)}
            </Text>
          ))}
        </View>
      </Page>
    </Document>
  );

  const pdfBlob = await pdf(<MyDocument />).toBlob();
  const pdfUrl = URL.createObjectURL(pdfBlob);
  const link = document.createElement('a');
  link.href = pdfUrl;
  link.download = 'product_comparison_cards.pdf';
  link.click();
};


const brandPricesMap = Amount.reduce((acc, { btb_brand_name, cp_total_price }) => {
  acc[btb_brand_name] = parseFloat(cp_total_price).toFixed(2);
  return acc;
}, {});



  return (
    <>
      <div className="main">
        <div className="section-btn d-flex" style={{ height: "170px", margin: "auto", alignItems: "end" }}>
          <div className="row justify-content-evenly" style={{ position: "fixed", width: "100%", height: "154px", zIndex: "1000", background: "#ffffff", alignItems: "end", top: "0", margin: "auto" }}>
            {brandNames.map((brand) => (
              <div key={brand} className="col-md-2">
                <h3 className="lg_brand-name" style={{ textAlign: 'center', color: '#000000', background: "rgb(227 227 227)", position: "sticky", marginBottom: "0", margin: "10px", borderRadius: "10px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontSize: "18px" }}>
                  {brand}
                </h3>
              </div>
            ))}
          </div>
        </div>

        <div className="comparison-container">
          <div className="row justify-content-evenly">
            {brandNames.map((brand) => (
              <div key={brand} className="col-md-2 mb-2 d-grid justify-content-center">
                <div style={{ padding: '10px', marginBottom: '20px', borderRadius: '10px' }}>
                  <h3 className="sm_brand-name" style={{ textAlign: 'center', color: '#000000', position: "sticky" }}>{brand}</h3>

                  {groupedProducts[brand].map((product, index) => (
                    <div key={index} className="card custom-card rounded-lg mb-4">
                      <img
                        // src={product.image ? `https://th.bing.com/th/id/OIP.NOH8bBZLrC_5WpwupOJNkwHaEX?rs=1&pid=ImgDetMain` : 'https://th.bing.com/th/id/OIP.NOH8bBZLrC_5WpwupOJNkwHaEX?rs=1&pid=ImgDetMain'}
                    src={product.image ? `http://192.168.0.139:8965/MI_Applications/BTrading/BrandLogo/${product.image}` : 'https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI='}
                       
                        alt={product.product}
                      />
                      <div className="card-body">
                        <h5 className="card-title text-center text-dark">{product.product}</h5>
                        <p className="card-text text-muted"><b>Code: <span>{product.productCode}</span></b></p>
                        <p className="card-text text-primary">MRP: <span>₹{product.mrp}</span></p>
                        <p className="card-text font-weight-bold">Sale Price: <span>₹{product.salePrice}</span></p>
                        <p className="card-text text-success">Discount: <span>{product.discount}%</span></p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {infoo && infoo.length > 0 && ( 
        <div>
            {/* <p style={{ color: "#000000", textAlign: "left",fontSize:"23px" ,marginTop:"10px",marginBottom:"0px" }}>Comparison Report For</p> */}
            {/* <p style={{ color: "#000000", textAlign: "left",fontSize:"18px",marginBottom:"0px",color:"#0e7985",marginLeft:"50px" }}>Customer Name - {infoo[0].btcust_customer_name}&nbsp;{infoo[0].btl_lead_name}</p> */}
            {/* <p style={{ color: "#000000", textAlign: "left",fontSize:"16px" ,marginBottom:"0px",marginTop:"0px",marginLeft:"50px" }}>Contact Number - {infoo[0].btcust_contact_number}&nbsp;{infoo[0].btl_contact_number}</p> */}
            {/* <p style={{ color: "#000000", textAlign: "left",fontSize:"16px" ,marginTop:"0px",marginLeft:"50px" }}>MailID - {infoo[0].btl_email}&nbsp;{infoo[0].btcust_email}</p> */}
           
            {/* <h3>Lead Name - {infoo[0].lead_name}</h3> */}
        </div>
    )}
        </div>
        {/* Total Price Section outside the comparison-container */}
        <div className="comparison-container mt-0">
          <div className="row justify-content-evenly">
            {brandNames.map((brand) => (
              <div key={brand} className="col-md-2 text-center">
                <div className="custom-card rounded-lg">
                  <div className="text-dark font-weight-bold">
                    Total: ₹
                    {groupedProducts[brand].reduce((total, product) => total + parseFloat(product.salePrice || 0), 0).toFixed(2)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>


        <div className="comparison-container mt-0">
       <p style={{fontSize:"18px",fontWeight:"600",textAlign:"center",color:"rgb(13, 119, 142)"}}>Total Amount for Batroomset {quantity} is</p> 
  <div className="row justify-content-evenly"style={{marginTop:"-10px"}}>
    {brandNames.map((brand) => (
      
      <div key={brand} className="col-md-2 text-center">
        <div className="custom-card rounded-lg">
          <div className="text-dark font-weight-bold">
            {brand}: ₹{brandPricesMap[brand] || '0.00'}
          </div>
        </div>
      </div>
    ))}
  </div>
        </div>



        {/* <button onClick={generatePDF} className="BtnAdd">
          Download PDF
        </button> */}

              <button class="Btn" onClick={generatePDF}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg sign" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />

                  </svg>
                  <div class="text">Download PDF</div>
              </button>

        <InfoCard />
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontFamily: 'Helvetica',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh', // Ensures page takes full height
  },
  // pageHeader: {
  //   marginBottom: 5,
  //   textAlign: 'center',
  // },
  pageTitle: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  productsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap', // Wrap items when necessary
    justifyContent: 'space-between', // Spread products evenly across the page
    flexGrow: 1, // Let this section take up available space
  },
  brandSection: {
    width: '24%', // 4 columns per row (100% / 4 = 25% per column, leaving space)
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: 10,
  },
  brandTitle: {
    fontSize: 9,
    fontWeight: 'bold',
    marginBottom: 5,
    textAlign: 'center',
    backgroundColor: '#e3e3e3',
    borderRadius: 5,
  },
  // productsList: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'flex-start',
  // },
  productCard: {
    width: '100%',
    padding: 8,
    marginBottom: 7,
    borderRadius: 8,
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0 0 5px rgba(0,0,0,0.1)',
  },
  productImage: {
    width: '80%', // Adjust image width
    height: 80, // Set fixed height
    objectFit: 'cover',
    borderRadius: 5,
    marginBottom: 5,
  },
  productName: {
    fontSize: 9, // Font size for the product name
    fontWeight: 'bold', // Make the name bold
    textAlign: 'center',
    marginBottom: 5,
    color: '#333',
  },
  label: {
    fontSize: 8,
    color: '#777',
    marginBottom: 2,
  },
  value: {
    fontSize: 9,
    color: '#333',
    marginBottom: 5,
  },
  MRPlabel: {
    fontSize: 8,
    color: '#777',
    marginBottom: 2,
  },
  MRPvalue: {
    fontSize: 9,
    color: '#333',
    marginBottom: 5,
  },
  discountValue: {
    fontSize: 9,
    color: '#D9534F', // Red color for discount
  },

  pageHead:{
    fontSize:9,
    backgroundColor:'lightblue',
    display: "flex",
    justifyContent: "center",
    paddingTop:2,
    alignItems:"center",
    textAlign: "center",
  },
  headerText :{
height: 15,
  },

  infoText :{
    fontSize:8,
  },
  subHeaderText :{
  fontSize:8,
  },
  totalContainer: {
    fontSize: 9,
    flexDirection: 'row', // Align items in a row
    justifyContent: 'space-around', // Distribute the totals evenly
  },
});


export default ComparisionReport;
