import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';


export default function QuotationGridReport() {
    const [infoo, setInfoo] = useState([]); // Initialize as an empty array
    const [loading, setLoading] = useState(false); // Track loading state
    const [currentPage, setCurrentPage] = useState(1); // Track the current page
    const itemsPerPage = 10; // Items per page
    const [showPopup, setShowPopup] = useState(false); // To show/hide the confirmation popup
    const [selectedItem, setSelectedItem] = useState(null); // Store selected item for deactivation/activation
    const [isDeactivated, setIsDeactivated] = useState({}); // Track deactivation status of each product
    const [searchQuery, setSearchQuery] = useState(''); // State for the search query

    const em_id = sessionStorage.getItem('em_id');

    const navigate = useNavigate();
    //session manage
    useEffect(() => {
        const em_id = sessionStorage.getItem('em_id');
        if (!em_id) {
            navigate('/');
        }
    }, [navigate]);

    // Function to send the POST request and fetch data
    const fetchData = () => {
        setLoading(true); // Start loading before request
        axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getLeadCustFinalQuoteGrid', {
            authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
        })
            .then(res => {
                // Ensure res.data is an array before setting it to infoo
                setInfoo(Array.isArray(res.data.jsArray) ? res.data.jsArray : []);
                setLoading(false); // Set loading to false after response
                // console.log(res.data.jsArray);
            })
            .catch(error => {
                setLoading(false); // Set loading to false in case of error
                console.error('There was an error!', error);
            });
    };

    // Fetch data on page load
    useEffect(() => {
        fetchData();
    }, []); // Empty dependency array ensures this runs only once on component mount

    // Define totalPages based on the data and itemsPerPage
const totalPages = Math.ceil(infoo.length / itemsPerPage);

// Function to handle page changes
const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
    }
};


    // Calculate the index of the first and last item of the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Get the items to display on the current page
    const currentItems = Array.isArray(infoo) ? infoo.slice(indexOfFirstItem, indexOfLastItem) : [];

    { infoo.length === 0 && !loading && <p>No data available</p> }

    // Change the page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Generate page numbers for pagination
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(infoo.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    // Handle the DeActivate button click
    const handleDeactivateClick = (item) => {
        setSelectedItem(item); // Store selected item for deactivation
        setShowPopup(true); // Show the popup for confirmation
    };


    // Handle the Confirm Deactivation/Activation
    const handleConfirmDeactivate = () => {
        if (selectedItem) {
            axios.post('https://midrserver.co.in:8289/trading_application/rest/menuService/setDeactivateProductMasterDetails', {
                one: selectedItem.btp_product_id,
                two: em_id, // Assuming "1" means deactivate; adapt based on backend logic
                authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
            })
                .then(response => {
                    if (response.data === "Success" && selectedItem.btp_product_status === "Active") {
                        setIsDeactivated(prevState => ({
                            ...prevState,
                            [selectedItem.btp_product_id]: true // Mark as deactivated
                        }));
                    } else if (response.data === "Product activated successfully" && selectedItem.btp_product_status === "Inactive") {
                        setIsDeactivated(prevState => ({
                            ...prevState,
                            [selectedItem.btp_product_id]: false // Mark as active
                        }));
                    }
                    setShowPopup(false); // Close the popup after success
                })
                .catch(error => {
                    console.error('Deactivation failed:', error);
                    setShowPopup(false); // Close the popup on failure
                });
        }
    };

    // Handle Product Click for navigating to the product page
    const handleNameClick = (item) => {
        sessionStorage.setItem('selectedCustomerQuoteId', item.bca_lead_id); // Store product ID
        navigate('/leadCompareMasterReport'); // Navigate to the ProductMaster page
    };

    //Searching functinality 

    // Function to search products by name(By Lead Name)
    const searchByName = (name) => {
        setLoading(true); // Start loading before request
        axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getLeadCustFinalQuoteGrid', {
            six: name,
            authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs",
            //  name: name  // Pass the name as a filter
        })
            .then(res => {
                setInfoo(Array.isArray(res.data.jsArray) ? res.data.jsArray : []);
                setLoading(false); // Set loading to false after response
            })
            .catch(error => {
                setLoading(false); // Set loading to false in case of error
                console.error('Error searching by name:', error);
            });
    };

    const searchById = (id) => {
        setLoading(true);  // Start loading before the request
        axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getLeadCustFinalQuoteGrid', {
            two: id,  // Pass the ID as a parameter
            authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
        })
            .then(res => {
                setInfoo(Array.isArray(res.data.jsArray) ? res.data.jsArray : []);  // Set the data from response
                setLoading(false);  // Stop loading
            })
            .catch(error => {
                setLoading(false);  // Stop loading in case of error
                console.error('Error searching by ID:', error);
            });
    };

    const handleSearchChange = (e) => {
        const searchQuery = e.target.value.trim();  // Get the search query from the input field
        setSearchQuery(searchQuery);  // Update the search query state

        if (searchQuery) {
            if (!isNaN(searchQuery)) {
                // If the query is a number, search by ID
                searchById(searchQuery);
            } else {
                // Otherwise, search by name
                searchByName(searchQuery);
            }
        } else {
            fetchData();  // If the input is empty, fetch all data again
        }
    };

    //clear search 
    // Function to clear the search input
    const clearSearch = () => {
        setSearchQuery('');  // Clear the search query
        fetchData();  // Re-fetch the data when the search is cleared
    };

    // Track the hovered item index
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseEnter = (index) => setHoveredIndex(index);
    const handleMouseLeave = () => setHoveredIndex(null);

    return (
        <div className="container mb-5" >
            <div className="row d-flex justify-content-center">
            </div>

            <div className="row d-flex justify-content-evenly" style={{ marginTop: "85px" }}>
            <p style={{ flex: 1, fontSize: "23px", fontWeight: "600", color: "#4CAF50" }}>Comparison Grid Report </p>

                <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-1">
                    {/* Add Input Field for Search */}
                    <div className="input-group" style={{ position: 'relative' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Name or Comparison ID"
                            value={searchQuery}  // Bind input value to searchQuery state
                            onChange={handleSearchChange}  // Trigger handleSearchChange when the user types
                            style={{ width: "250px", backgroundColor: "white",  textAlign: "center" }}
                        />

                        {/* Conditionally render the cross icon if there's text in the input */}
                        {searchQuery && (
                            <div
                                onClick={clearSearch}  // When the icon is clicked, clear the search
                                style={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    right: '10px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    zIndex: 0// Ensure the icon stays above the input field content
                                }}
                            >
                                <i className="bi bi-x-circle" style={{ fontSize: '20px', color: '#888' }}></i> {/* Bootstrap cross icon */}
                            </div>
                        )}
                    </div>
                </div>
            </div>



            <div style={{ overflowX: "auto", maxHeight: "100%", overflowY: "auto", scrollbarWidth: "none", marginTop: "-10px", borderRadius: "20px" }}>
                {infoo.length > 0 && (
                    <div>
                        <table className="table" style={{ background: "rgb(233, 231, 232)", borderRadius: "20px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", width: "100%" }}>
                            <thead>
                                <tr >
                                    <th style={{ fontSize: "15px", background: "#4CAF50", color: "white" }} >No.</th>
                                    <th style={{ fontSize: "15px", background: "#4CAF50", color: "white" }} >Name</th>
                                    <th style={{ fontSize: "15px", background: "#4CAF50", color: "white" }} >Comparison Id</th>
                                    <th style={{ fontSize: "15px", background: "#4CAF50", color: "white" }} >Contact</th>
                                    <th style={{ fontSize: "15px", background: "#4CAF50", color: "white" }} >Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                            {indexOfFirstItem + index + 1}
                                        </td>
                                        <td
                                            onClick={() => handleNameClick(item)}
                                            style={{
                                                cursor: 'pointer', color: "rgb(14 91 159)",
                                                textDecoration: hoveredIndex === index ? 'underline' : 'none',
                                                backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
                                            }}
                                            title={item.btl_lead_name}
                                            onMouseEnter={() => handleMouseEnter(index)}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            {item.btl_lead_name && item.btl_lead_name.length > 20
                                                ? item.btl_lead_name.slice(0, 20) + '...'
                                                : item.btl_lead_name || 'No Name Available'}
                                        </td>
                                        <td style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                            {item.bca_comparison_id}
                                        </td>
                                        <td style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                            {item.btl_contact_number}
                                        </td>
                                        <td style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                            {item.bca_assigned_at}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                )}
            </div>
            {infoo.length === 0 && !loading && <p>No data available</p>}

            {/* Pagination controls */}
<div className="pagination-controls" style={{marginTop:"-10px"}}>
    <button
        onClick={() => handlePageChange(1)}
        disabled={currentPage === 1}
    >
        First
    </button>
    <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
    >
        Previous
    </button>
    <span>
        Page {currentPage} of {totalPages}
    </span>
    <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
    >
        Next
    </button>
    <button
        onClick={() => handlePageChange(totalPages)}
        disabled={currentPage === totalPages}
    >
        Last
    </button>
</div>


            {/* Confirmation Popup */}
            {showPopup && (
                <div className="modal" tabIndex="-1" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Deactivation</h5>
                                <button type="button" className="btn-close" onClick={() => setShowPopup(false)}></button>
                            </div>
                            <div className="modal-body">
                                <p>Do you really want to {isDeactivated[selectedItem.btp_product_id] ? "activate" : "deactivate"} this product?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowPopup(false)}>Cancel</button>
                                <button type="button" className="btn btn-primary" onClick={handleConfirmDeactivate}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
