import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'; // useNavigate for navigation

export default function PredefinedSetes() {
    const [infoo, setInfoo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [showPopup, setShowPopup] = useState(false); // State for popup visibility
    const [selectedItem, setSelectedItem] = useState(null); // Selected item for deactivation/activation
    const [isDeactivated, setIsDeactivated] = useState({}); // Track deactivated status for each item
    const navigate = useNavigate(); // useNavigate to navigate between pages

    const em_id = sessionStorage.getItem('em_id');

    //session manage
    useEffect(() => {
        const em_id = sessionStorage.getItem('em_id');
        if (!em_id) {
            navigate('/');
        }
    }, [navigate]);

    // Fetch series data
    const fetchData = () => {
        setLoading(true);
        axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/getAllPredefinedSets', {
            authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
        })
            .then(res => {
                setInfoo(Array.isArray(res.data) ? res.data : []);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.error('There was an error!', error);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Array.isArray(infoo) ? infoo.slice(indexOfFirstItem, indexOfLastItem) : [];

    // Handle pagination
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Handle Modify button click - use sessionStorage to store the selected series data
    const handleModifyClick = (item) => {
        sessionStorage.setItem('selectedSeriesId', item.bts_series_id); // Store the selected series in sessionStorage
        navigate('/hotelD/modifyseries'); // Navigate to the ModifySeries page
    };

    // Handle Deactivate button click - show popup
    const handleDeactivateClick = (item) => {
        setSelectedItem(item);
        setShowPopup(true); // Show confirmation popup

    };

    // Confirm Deactivation or Activation
    const handleConfirmDeactivate = () => {
        if (selectedItem) {
            axios.post('http://192.168.0.139:8965/trading_application/rest/menuService/setDeactivateSeriesMasterDetails', {
                one: selectedItem.bts_series_id,
                two: em_id,
                authApiKey: "ytfkenaojjawmbjnbsyyj-vhvbs"
            })
                .then(response => {
                    if (response.data === "Success" && selectedItem.bts_series_status === "Active") {
                        setIsDeactivated(prevState => ({
                            ...prevState,
                            [selectedItem.bts_series_id]: true // Set as deactivated
                        }));
                    } else if (response.data === "Series activated successfully" && selectedItem.bts_series_status === "Inactive") {
                        setIsDeactivated(prevState => ({
                            ...prevState,
                            [selectedItem.bts_series_id]: false // Set as active
                        }));
                    }
                    setShowPopup(false); // Close the popup after success
                })
                .catch(error => {
                    console.error('Deactivation failed:', error);
                    setShowPopup(false); // Close the popup on failure
                });
        }
    };

    const handleNameClick = (item) => {
        sessionStorage.setItem('selectedSetId', item.btps_set_id); // Store product ID 
        sessionStorage.setItem('selectedSetName', item.btps_set_name); // Store product ID
        sessionStorage.setItem('selectedFinalTotal', item.btps_set_price); // Store product ID
        navigate('/preDefinedMaster'); // Navigate to the ProductMaster page
    };


    // Generate page numbers for pagination
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(infoo.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="container mb-5">

            <div className='container' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p style={{ color: "#000000", textAlign: "center", flex: 1, fontSize: "23px", marginTop: "75px" }}>All PreDefined Sets</p>
            </div>

            <div style={{ overflowX: "auto", maxHeight: "100%", overflowY: "auto", scrollbarWidth: "none" }}>
                {infoo.length > 0 && (
                    <div>
                       
                       <div className='container'>
                       <div class="row">  
                           
                                {currentItems.map((item, index) => (
                                    <div class="col col-lg-3 col-md-4 col-sm-12">
                                    <div class="card" style={{height:"auto",width:"auto"}}>
                                        <div class="card-body">
                                            <h5
                                            onClick={() => handleNameClick(item)}
                                            style={{ cursor: 'pointer', color: "rgb(14 91 159)", textDecoration: "underline", fontSize: "15px" }}
                                            title={item.leadName}
                                        >{item.btps_set_name && item.btps_set_name.length > 20 ? item.btps_set_name.slice(0, 20) + '...' : item.btps_set_name}</h5>
                                            <p class="card-text">{item.btps_set_sdate}</p>
                                            <p class="card-text">{item.btps_set_status}</p>
                                            <p class="card-text">{item.btps_set_price}</p>
                                            <a href="#" class="btn btn-primary">Go somewhere</a>
                                        </div>
                                    </div>
                                </div>
                                    
                                  
                                ))}
                           
                      </div>  </div>

                        <nav className='mt-2 mb-2'>
                            <ul className="pagination justify-content-end">
                                {pageNumbers.map(number => (
                                    <li key={number} className="page-item">
                                        <button
                                            onClick={() => paginate(number)}
                                            className={`page-link ${currentPage === number ? 'active' : ''}`}
                                        >
                                            {number}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                )}
            </div>

            {infoo.length === 0 && !loading && <p>No data available</p>}

            
        </div>
    );
}
